import z from 'zod';
export const AttachmentType = {
    Pdf: 'pdf',
    Image: 'image',
};
export const AttachmentTypeValues = Object.values(AttachmentType);
export const fileTypeSchema = z.string().pipe(z.enum(AttachmentTypeValues));
export const baseLinkedAttachmentSchema = z.object({
    id: z.string(),
    attachmentId: z.string(),
    attachmentUrl: z.string(),
    fileType: fileTypeSchema,
    pageNumber: z.number(),
    isPrimary: z.boolean(),
    isNextPage: z.boolean(),
});
export const linkedPDFAttachmentSchema = baseLinkedAttachmentSchema
    .omit({ pageNumber: true })
    .extend({ pageNumber: z.number() });
/** isLinkedPDFAttachment guard return whether attachment is a PDF or not */
export function isLinkedPDFAttachment(attachment) {
    return attachment.fileType === AttachmentType.Pdf;
}
export const attachmentSchema = z.object({
    id: z.string(),
    name: z.string(),
    file: z.string(),
    fileType: fileTypeSchema,
    owner: z.string().nullable(),
});
// These types get confusing but this is specifically when requesting the specifc attachments of an invoice or ticket
// we should probably refactor this to be more consistent
const baseAttachmentDetailSchema = z.object({
    id: z.string(),
    attachment: z.string(),
    isNextPage: z.boolean(),
    isPrimary: z.boolean(),
    pageNumber: z.number(),
});
export const invoiceAttachmentDetailSchema = baseAttachmentDetailSchema.extend({
    invoice: z.string(),
    isVoid: z.boolean(),
});
export const ticketAttachmentDetailSchema = baseAttachmentDetailSchema.extend({
    ticket: z.string(),
});
