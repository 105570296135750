import { alpha, Box, useTheme } from '@mui/material';
import type { PageProps, pdfjs } from 'react-pdf';
import { Page } from 'react-pdf';

interface IOutlineProps {
  pdf: pdfjs.PDFDocumentProxy;
  currentPage: number;
  onPageSelected?: (pageNum: number) => void;
  width: number;
}

interface ILiftAIPDFPage extends PageProps {
  pdf: pdfjs.PDFDocumentProxy;
}
// Hack to get access to a missing property
const LiftAIPDFPage = Page as unknown as React.FC<ILiftAIPDFPage>;

const Outline: React.FC<IOutlineProps> = ({ pdf, onPageSelected, currentPage, width }) => {
  const theme = useTheme();

  return (
    <>
      {[...Array(pdf.numPages)].map((_, i) => {
        const pageNum = i + 1;
        const isSelected = currentPage === pageNum;
        return (
          <Box
            key={i}
            onClick={onPageSelected ? () => onPageSelected(pageNum) : undefined}
            my={1}
            sx={{
              outline: isSelected ? `1px inset ${theme.palette.primary.main}` : 'none',
              position: 'relative',
            }}
          >
            <LiftAIPDFPage
              pageNumber={pageNum}
              pdf={pdf}
              width={width}
              renderAnnotationLayer={false}
            />
            {!isSelected && (
              <Box
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                style={{ backgroundColor: alpha(theme.palette.common.black, 0.12) }}
              />
            )}
          </Box>
        );
      })}
    </>
  );
};

export default Outline;
