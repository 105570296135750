import { Button } from '@mui/material';
import { useContext } from 'react';

import { UploadDocumentDialogFlowContext } from './UploadDocumentDialogFlow';

const UploadDocumentButton = () => {
  const { start } = useContext(UploadDocumentDialogFlowContext);

  return (
    <Button variant="contained" color="primary" onClick={() => start()}>
      Upload Document
    </Button>
  );
};
export default UploadDocumentButton;
