import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputBase } from '@mui/material';

export interface QuickFilterProps {
  searchValue: string;
  placeholder?: string;
  handleSearchValueChange: (value: string) => void;
}

export const QuickFilterInput = ({
  searchValue,
  handleSearchValueChange,
  placeholder,
}: QuickFilterProps) => {
  return (
    <Box
      component="form"
      width="100%"
      borderRadius={1}
      display="flex"
      sx={{
        bgcolor: (theme) => theme.palette.action.selected,
      }}
      alignContent="stretch"
    >
      <IconButton type="button" sx={{ p: 6 / 8 }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1 }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        value={searchValue}
        onChange={(event) => {
          handleSearchValueChange(event.target.value);
        }}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': placeholder }}
      />
    </Box>
  );
};
