import DeleteOutline from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Card, CardActions, CardHeader, IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import type { UploadDocumentType } from '~/components/uploadDocument/AddManuallyDropdown';
import { EllipsisText } from '~/components/utils';

export interface IPdfPreviewProps {
  fileId: string;
  filePath: string;
  pageNumber?: number;
  title?: string;
  subtitle: UploadDocumentType;
  onDeleted?: (id: string) => void;
  onPreview?: () => void;
}

const PdfPreviewWithActions: React.FC<IPdfPreviewProps> = ({
  fileId,
  filePath,
  pageNumber = 1,
  subtitle,
  title,
  onDeleted,
  onPreview,
}: IPdfPreviewProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const theme = useTheme();
  const fileName = pdfjs.getPdfFilenameFromUrl(filePath);

  const MenuItemActions = () => {
    const open = Boolean(anchorEl);
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
        {onPreview ? (
          <MenuItem
            onClick={() => {
              onPreview();
            }}
            disableRipple
          >
            <VisibilityOutlinedIcon sx={{ marginRight: '10px' }} />
            Preview
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            onDeleted?.(fileId);
          }}
          disableRipple
        >
          <DeleteOutline sx={{ marginRight: '10px' }} />
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const downloadPdf = async () => {
    const response = await fetch(filePath);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  };

  return (
    <Card
      sx={{
        p: 0,
        boxShadow: 'none',
        border: 'none',
        '.react-pdf__Page__canvas': {
          imageRendering: 'crisp-edges;',
          backgroundColor: '#eee !important;',
          height: '150px !important;',
          width: '-webkit-fill-available !important;',
        },
        '.react-pdf__Page__textContent': {
          cursor: 'pointer;',
          userSelect: 'none;',
        },
        '.react-pdf__Page__textContent span': {
          cursor: 'pointer;',
          userSelects: 'none;',
        },
      }}
    >
      <Document file={filePath}>
        <Page
          pageNumber={pageNumber}
          height={200}
          scale={1}
          renderAnnotationLayer={false}
          onClick={async () => {
            // download pdf file
            await downloadPdf();
          }}
        />
      </Document>
      <CardActions
        disableSpacing
        sx={{
          p: 0,
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <CardHeader
          sx={{
            alignItems: 'start',
            p: theme.spacing(2),
            textAlign: 'left',
          }}
          avatar={<PictureAsPdfIcon sx={{ color: theme.palette.error.main }} aria-label="files" />}
          action={
            <>
              <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <MenuItemActions />
            </>
          }
          title={
            <>
              <EllipsisText>{title ?? fileName}</EllipsisText>
            </>
          }
          subheader={subtitle}
        />
      </CardActions>
    </Card>
  );
};

export default PdfPreviewWithActions;
