import { type InvoiceForm, InvoiceKind, type TicketDto } from '@liftai/asset-management-types';
import ReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined';
import RequestQuote from '@mui/icons-material/RequestQuote';
import {
  Alert,
  alpha,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { type FieldValues, FormProvider, useForm, type UseFormReturn } from 'react-hook-form';
import { useElementSize } from 'usehooks-ts';

import type { SupportingDataDto } from '~/components/uploadDocument/SupportingDataForm';
import { useSupportingDataForm } from '~/components/uploadDocument/SupportingDataForm';

import DialogBackNextActions from '../DialogBackNextActions';
import DialogTitleWithCloseButton from '../DialogTitleWithCloseButton';
import { DocumentPreview } from '../documentPreview';
import { UploadDocumentType } from './AddManuallyDropdown';
import { DefaultFields, DefaultForm } from './DefaultFields';
import { useInvoiceForm } from './InvoiceForm';
import SelectDocumentTypeDropdown from './SelectDocumentTypeDropdown';
import { useTicketForm } from './TicketForm';
import type { LiftAIDocumentUntagged } from './UploadDocumentDialogFlow';

interface ISelectUploadDocumentTypeDialogProps {
  open: boolean;
  onCancel: () => void;
  onDone: (taggedDocuments: LiftAIDocumentTagged[]) => void;
  documents: LiftAIDocumentUntagged[];
}

export type InvoiceEditable =
  | {
      /** `editInvoice` enables the form in edit mode for the invoice */
      editInvoice: boolean;
      /** `invoiceData` contains the invoice data to be edited */
      invoiceData: InvoiceForm;
    }
  | {
      editInvoice?: never;
      invoiceData?: never;
    };

export type LiftAIDocumentTagged = LiftAIDocumentUntagged & {
  documentType: UploadDocumentType;
  formData: unknown;
};

type SelectUploadDocumentTypeState = {
  currentDocumentIndex: number;
  taggedDocuments: LiftAIDocumentTagged[];
  lastTicketId: string | null;
  lastInvoiceId: string | null;
  lastInvoiceKind: UploadDocumentType | null;
  lastPropertyId: string | null;
  lastCarIds: string[];
  invoiceSelected: boolean;
  proposalSelected: boolean;
  ticketSelected: boolean;
  supportingDataMovingForward?: boolean;
  remainingPages?: number;
} & (
  | { name: 'fillingOutForm' }
  | { name: 'selecting'; selectedDocumentType: UploadDocumentType | null }
);

export type LiftAIFormProps<T = unknown, TSubmit = unknown> = {
  children: React.ReactNode;
  document?: LiftAIDocumentTagged;
  initialData?: T;
  onSubmit(data: TSubmit): void;
};

export type LAIUseFormReturn<T extends FieldValues, TSubmit = unknown> = {
  hookForm: UseFormReturn<T, TSubmit>;
  Form: React.FC<LiftAIFormProps<T>>;
  Fields: React.FC<{ documentType?: UploadDocumentType }>;
};

type UseCurrentFormReturn = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form: React.FC<LiftAIFormProps<any>>;
  Fields: React.FC<{ documentType?: UploadDocumentType }>;
};

const useCurrentForm = (
  documentType: UploadDocumentType = UploadDocumentType.initial,
): UseCurrentFormReturn => {
  const ticketForm = useTicketForm();
  const invoiceForm = useInvoiceForm();
  const supportingDataForm = useSupportingDataForm();

  const defaults: UseCurrentFormReturn = {
    hookForm: useForm(),
    Form: DefaultForm,
    Fields: DefaultFields,
  };

  switch (documentType) {
    case UploadDocumentType.ticket:
      return {
        hookForm: ticketForm.hookForm,
        Form: ticketForm.Form,
        Fields: ticketForm.Fields,
      };
    case UploadDocumentType.invoice:
    case UploadDocumentType.proposal:
      return {
        hookForm: invoiceForm.hookForm,
        Form: invoiceForm.Form,
        Fields: invoiceForm.Fields,
      };
    case UploadDocumentType.supportingData:
    case UploadDocumentType.invoiceContinued:
    case UploadDocumentType.proposalContinued:
    case UploadDocumentType.ticketContinued:
      return {
        hookForm: supportingDataForm.hookForm,
        Form: supportingDataForm.Form,
        Fields: supportingDataForm.Fields,
      };
    default:
      return defaults;
  }
};

const DocumentTypeIcon = ({ documentType }: { documentType: UploadDocumentType }) => {
  switch (documentType) {
    case UploadDocumentType.ticket:
      return <ReceiptLongOutlined />;
    case UploadDocumentType.invoice:
    case UploadDocumentType.proposal:
      return <RequestQuote />;
    default:
      return null;
  }
};

const SelectUploadDocumentTypeDialog: React.FC<
  ISelectUploadDocumentTypeDialogProps & InvoiceEditable
> = ({ open, onCancel, onDone, documents, editInvoice, invoiceData }) => {
  const [state, setState] = useState<SelectUploadDocumentTypeState>({
    name: 'selecting',
    currentDocumentIndex: 0,
    selectedDocumentType: null,
    taggedDocuments: documents.map((doc, i) => ({
      ...doc,
      pageNum: i + 1,
      documentType: UploadDocumentType.initial,
      formData: {},
    })),
    lastTicketId: null,
    lastInvoiceId: null,
    lastInvoiceKind: null,
    lastPropertyId: null,
    lastCarIds: [],
    invoiceSelected: false,
    proposalSelected: false,
    ticketSelected: false,
    supportingDataMovingForward: false,
  });
  const documentTypes = useMemo(() => {
    const options = [
      UploadDocumentType.invoice,
      UploadDocumentType.ticket,
      state.proposalSelected ? UploadDocumentType.proposalContinued : UploadDocumentType.proposal,
      UploadDocumentType.supportingData,
    ];

    if (state.invoiceSelected) {
      options.splice(1, 0, UploadDocumentType.invoiceContinued);
    }

    if (state.ticketSelected) {
      options.splice(2, 0, UploadDocumentType.ticketContinued);
    }

    return options;
  }, [state.invoiceSelected, state.proposalSelected, state.ticketSelected]);
  const theme = useTheme();
  const { currentDocumentIndex, taggedDocuments } = state;
  const [documentPreviewContainerRef, { height: documentPreviewContainerHeight }] =
    useElementSize();
  const numDocuments = documents.length;
  const currentDocument: LiftAIDocumentTagged | undefined = taggedDocuments[currentDocumentIndex];
  const currentPage = currentDocumentIndex + 1;

  const handleUploadDocumentTypeSelected = useCallback(
    (documentType: UploadDocumentType) => {
      if (currentDocument.fileType !== 'pdf') {
        return;
      }

      if (editInvoice) {
        return setState((state) => ({
          ...state,
          lastInvoiceId: invoiceData.id,
          lastPropertyId: invoiceData.propertyId,
          lastCarIds: invoiceData.carIds,
          selectedDocumentType: documentType,
        }));
      }

      return setState((state) => ({
        ...state,
        selectedDocumentType: documentType,
      }));
    },
    [currentDocument.fileType, editInvoice, invoiceData],
  );

  const handleNext = useCallback(
    (...args: unknown[]) => {
      if (state.name === 'selecting' && state.selectedDocumentType) {
        const nextDocs = [...state.taggedDocuments];

        const nextDocument = {
          ...currentDocument,
          documentType: state.selectedDocumentType,
        };

        switch (state.selectedDocumentType) {
          case UploadDocumentType.ticket:
            nextDocument.formData = {
              invoiceId: state.lastInvoiceId,
              propertyId: state.lastPropertyId,
              carIds: state.lastCarIds,
            };
            break;
          case UploadDocumentType.invoice:
          case UploadDocumentType.proposal: {
            if (editInvoice) {
              nextDocument.formData = invoiceData;
              setState((curState) => ({ ...curState, lastInvoiceId: invoiceData.id }));
            } else {
              nextDocument.formData = {
                invoiceId: state.lastInvoiceId,
                kind:
                  state.selectedDocumentType === UploadDocumentType.invoice
                    ? InvoiceKind.Invoice
                    : InvoiceKind.Proposal,
              };
            }
            break;
          }
          case UploadDocumentType.ticketContinued:
            nextDocument.formData = {
              ticketId: state.lastTicketId,
              isContinued: true,
            };
            break;
          case UploadDocumentType.proposalContinued:
          case UploadDocumentType.invoiceContinued:
            nextDocument.formData = {
              invoiceId: state.lastInvoiceId,
              invoiceKind: state.lastInvoiceKind,
              isContinued: true,
            };
            break;
          case UploadDocumentType.supportingData:
            nextDocument.formData = {
              invoiceId: state.lastInvoiceId,
              invoiceKind: state.lastInvoiceKind,
              propertyId: state.lastPropertyId,
              carIds: state.lastCarIds,
              supportingDataMovingForward: state.supportingDataMovingForward,
              remainingPages: state.remainingPages,
            };
            break;
          default:
            nextDocument.formData = {};
        }

        nextDocs[currentDocumentIndex] = nextDocument;

        setState((curState) => {
          return {
            ...curState,
            name: 'fillingOutForm',
            taggedDocuments: nextDocs,
          };
        });
      }
    },
    [currentDocument, currentDocumentIndex, state, editInvoice, invoiceData],
  );

  const handleBack = useCallback(() => {
    if (state.name === 'fillingOutForm') {
      setState({
        ...state,
        name: 'selecting',
        selectedDocumentType: currentDocument.documentType,
      });
    } else {
      if (currentDocumentIndex === 0) {
        onCancel();
      } else {
        setState((state) => ({
          ...state,
          name: 'fillingOutForm',
          currentDocumentIndex: currentDocumentIndex - 1,
        }));
      }
    }
  }, [state, onCancel, currentDocument, currentDocumentIndex]);

  const handleSkip = useCallback(() => {
    if (currentDocumentIndex >= taggedDocuments.length - 1) {
      return onDone([...state.taggedDocuments]);
    }

    if (state.name === 'selecting') {
      setState({
        ...state,
        name: 'selecting',
        currentDocumentIndex: currentDocumentIndex + 1,
      });
    }
  }, [currentDocumentIndex, onDone, state, taggedDocuments.length]);

  const onSubmit = useCallback(
    (formData: unknown) => {
      const nextDocs = [...state.taggedDocuments];
      nextDocs[currentDocumentIndex] = {
        ...currentDocument,
        formData,
      };

      const hasSavedMultipleSupportingData = () =>
        currentDocument.documentType === UploadDocumentType.supportingData &&
        (currentDocument.formData as SupportingDataDto).supportingDataMovingForward;
      const thereAreNotDocumentsLeft = () =>
        state.name === 'fillingOutForm' && currentDocumentIndex + 1 === numDocuments;
      if (hasSavedMultipleSupportingData() || thereAreNotDocumentsLeft()) {
        return onDone([...nextDocs]);
      }

      setState((curState) => {
        const {
          lastInvoiceId,
          lastPropertyId,
          lastCarIds,
          lastInvoiceKind = null,
          lastTicketId = null,
        } = currentDocument.documentType === UploadDocumentType.invoice
          ? {
              lastInvoiceId: (formData as InvoiceForm).id,
              lastPropertyId: (formData as InvoiceForm).propertyId,
              lastCarIds: (formData as InvoiceForm).carIds,
              lastInvoiceKind: UploadDocumentType.invoice,
            }
          : currentDocument.documentType === UploadDocumentType.proposal
            ? {
                lastInvoiceId: (formData as InvoiceForm).id,
                lastPropertyId: (formData as InvoiceForm).propertyId,
                lastCarIds: (formData as InvoiceForm).carIds,
                lastInvoiceKind: UploadDocumentType.proposal,
              }
            : currentDocument.documentType === UploadDocumentType.ticket
              ? {
                  lastInvoiceId: (formData as TicketDto).invoiceId,
                  lastPropertyId: (formData as TicketDto).propertyId,
                  lastCarIds: (formData as TicketDto).carIds,
                  lastTicketId: (formData as TicketDto).id,
                }
              : {
                  lastInvoiceId: curState.lastInvoiceId,
                  lastTicketId: curState.lastTicketId,
                  lastPropertyId: curState.lastPropertyId,
                  lastCarIds: curState.lastCarIds,
                };

        const invoiceSelected =
          currentDocument.documentType === UploadDocumentType.invoice ||
          currentDocument.documentType === UploadDocumentType.invoiceContinued;
        const proposalSelected =
          currentDocument.documentType === UploadDocumentType.proposal ||
          currentDocument.documentType === UploadDocumentType.proposalContinued;
        const ticketSelected =
          currentDocument.documentType === UploadDocumentType.ticket ||
          currentDocument.documentType === UploadDocumentType.ticketContinued;

        return {
          ...curState,
          name: 'selecting',
          selectedDocumentType: null,
          currentDocumentIndex: currentDocumentIndex + 1,
          taggedDocuments: nextDocs,
          lastTicketId,
          lastInvoiceId,
          lastInvoiceKind,
          lastPropertyId,
          lastCarIds,
          invoiceSelected,
          proposalSelected,
          ticketSelected,
        };
      });
    },
    [
      currentDocument,
      currentDocumentIndex,
      numDocuments,
      onDone,
      state.name,
      state.taggedDocuments,
    ],
  );

  const { hookForm, Form, Fields } = useCurrentForm(currentDocument?.documentType);
  const {
    formState: { isSubmitting },
  } = hookForm;

  const actionBtns = useMemo(() => {
    const nextEnabled =
      (state.name === 'fillingOutForm' || state.selectedDocumentType != null) && !isSubmitting;
    // Only show next until we can fix the backend update functionality
    // Right now a lot of errors are thrown in the InvoiceSerializer when trying to update an invoice
    // Haven't tested to see what is needed for the other document types
    // When it's fixed we can just remove the `actionBtns` attribute to use the default behavior
    return {
      next: { label: isSubmitting ? 'Saving Page' : 'Next', enabled: nextEnabled },
      ...(state.name === 'selecting' && { skip: { label: 'Skip this Page', enabled: true } }),
    };
  }, [isSubmitting, state]);

  const replaceInvoice =
    editInvoice &&
    state.name === 'selecting' &&
    (state.selectedDocumentType === UploadDocumentType.invoice ||
      state.selectedDocumentType === UploadDocumentType.invoiceContinued ||
      state.selectedDocumentType === UploadDocumentType.proposal ||
      state.selectedDocumentType === UploadDocumentType.proposalContinued);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth={false}
      sx={{
        // Needed to make the dialog full height so that the DocumentPreview dimensions can be correctly calculated
        '& > .MuiDialog-container > .MuiPaper-root': {
          height: '100%',
        },
      }}
    >
      <FormProvider {...hookForm}>
        <Form document={currentDocument} onSubmit={onSubmit} initialData={currentDocument.formData}>
          {currentDocument.fileType === 'pdf' ? (
            <>
              <DialogTitleWithCloseButton onClose={onCancel} title="Upload Document" />
              <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container minHeight={0} flexGrow={1}>
                  <Grid item xs={7} height="100%" ref={documentPreviewContainerRef}>
                    <DocumentPreview
                      documents={taggedDocuments}
                      currentPage={currentPage}
                      containerHeight={documentPreviewContainerHeight ?? 0}
                    />
                  </Grid>
                  <Grid item order={1} xs={5} px={1}>
                    <Typography variant="body1" mb={2}>
                      {numDocuments > 0 ? `Page ${currentPage} of ${numDocuments}` : null}
                    </Typography>
                    {state.name === 'selecting' ? (
                      <>
                        <Typography variant="h6" mt={6} mb={1}>
                          What type of document is this page?
                        </Typography>
                        <SelectDocumentTypeDropdown
                          onChange={handleUploadDocumentTypeSelected}
                          documentTypes={documentTypes}
                          value={state.selectedDocumentType}
                        />
                        {state.selectedDocumentType === UploadDocumentType.supportingData && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.supportingDataMovingForward}
                                onChange={({ target }) => {
                                  setState((curState) => ({
                                    ...curState,
                                    supportingDataMovingForward: target.checked,
                                    remainingPages: numDocuments - currentPage,
                                  }));
                                }}
                              />
                            }
                            label="Mark subsequent pages as Supporting Data"
                            sx={{ marginLeft: theme.spacing(0.5) }}
                          />
                        )}
                        {replaceInvoice ?? false ? (
                          <Alert sx={{ marginTop: theme.spacing(4) }} severity="warning">
                            Uploading a new {state.selectedDocumentType} PDF will void the existing{' '}
                            {state.selectedDocumentType} and keep it as supporting data.
                          </Alert>
                        ) : null}
                      </>
                    ) : null}
                    {state.name === 'fillingOutForm' ? (
                      <>
                        <Typography variant="h6" my={1} color="primary">
                          <IconButton
                            color="primary"
                            sx={{
                              backgroundColor: alpha(theme.palette.primary.main, 0.12),
                              marginRight: theme.spacing(2),
                            }}
                          >
                            {currentDocument.documentType ? (
                              <DocumentTypeIcon documentType={currentDocument.documentType} />
                            ) : null}
                          </IconButton>
                          {currentDocument.documentType ?? ''}
                        </Typography>
                        <Fields documentType={currentDocument.documentType} />
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </DialogContent>
            </>
          ) : (
            <DialogContent>Image Support Not Implemented</DialogContent>
          )}

          <DialogBackNextActions
            onBack={handleBack}
            onNext={handleNext}
            onSkip={handleSkip}
            actionBtns={actionBtns}
          />
        </Form>
      </FormProvider>
    </Dialog>
  );
};

export default SelectUploadDocumentTypeDialog;
