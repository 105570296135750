import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { InvoiceDetailDrawerFlowContext } from '~/components/invoices/InvoiceDetailDrawerContext';
import Main from '~/components/layout/Main';

function App() {
  const { isOpen } = useContext(InvoiceDetailDrawerFlowContext);

  return (
    <Main sidebarOpen={isOpen}>
      <Outlet />
    </Main>
  );
}

export default App;
