import {
  API_InvoiceFilterResponse,
  API_InvoiceFilterSearchParamsInput,
} from '@liftai/asset-management-types';
import type { Fetcher } from 'swr';
import useSWR from 'swr';

import { getApiClient } from '~/utils/api';

export const invoiceFilterFetcher: Fetcher<
  API_InvoiceFilterResponse,
  readonly ['invoice', UseInvoicesParameters]
> = async ([_, params]) => {
  const apiClient = getApiClient();
  const response = await apiClient.invoices.filter(params ?? {});

  return response;
};

export type UseInvoicesParameters = API_InvoiceFilterSearchParamsInput | undefined;
export type UseInvoicesHook = {
  invoices: API_InvoiceFilterResponse['results'] | undefined;
  metadata: API_InvoiceFilterResponse['metadata'] | undefined;
  isLoading: boolean;
  error: unknown;
};

/**
 * Returns all Invoices the authenticated user has access to.
 */
export default function useInvoices(
  options: UseInvoicesParameters = {},
  enabled = true,
): UseInvoicesHook {
  const { data, error } = useSWR(
    enabled ? (['invoice', options] as const) : null,
    invoiceFilterFetcher,
  );

  return {
    invoices: data?.results,
    metadata: data?.metadata,
    // You would think we would use `isLoading` but I assume it's to prevent a flash of loading state
    isLoading: !error && !data,
    error,
  };
}
