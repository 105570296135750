import type { SkeletonProps } from '@mui/material';
import {
  List,
  ListItem,
  Skeleton as MUISkeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

export default function Skeleton(props?: SkeletonProps) {
  return <MUISkeleton animation="wave" {...props} />;
}

type SkeletonListProps = {
  count?: number;
  props?: SkeletonProps;
  children?: (S: typeof Skeleton) => React.ReactNode;
};

export const SkeletonList = ({ count = 3, props, children }: SkeletonListProps) => (
  <List>
    {Array.from({ length: count }).map((_, index) => (
      <ListItem key={index}>{children ? children(Skeleton) : <Skeleton {...props} />}</ListItem>
    ))}
  </List>
);

interface SkeletonTableProps {
  rows?: number;
  columns: string[];
  hideHeader?: boolean;
}

export const SkeletonTable = ({ rows = 3, columns, hideHeader = false }: SkeletonTableProps) => (
  <Table>
    {!hideHeader && (
      <TableHead>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell key={index}>{column}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    )}
    <TableBody>
      {Array.from({ length: rows }).map((_, index) => (
        <TableRow key={index}>
          {columns.map((_, index) => (
            <TableCell key={index}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
