import { DialogTitle, Grid, IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid-premium';

interface IDialogTitleProps {
  onClose: () => void;
  title: string;
}
const DialogTitleWithCloseButton: React.FC<IDialogTitleProps> = ({ onClose, title }) => {
  return (
    <>
      <DialogTitle variant="h6" component="h6">
        <Grid container justifyContent="space-between">
          <Grid
            item
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '90%', // or set your desired width, e.g., '200px'
            }}
          >
            {title}
          </Grid>
          <Grid sx={{ ml: 2 }} item>
            <IconButton onClick={onClose}>
              <GridCloseIcon />
            </IconButton>{' '}
          </Grid>
        </Grid>
      </DialogTitle>
    </>
  );
};
export default DialogTitleWithCloseButton;
