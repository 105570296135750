import { z } from 'zod';
export const validateMaskHHmm = (value) => {
    const [hourPart, minutePart] = value.split(':');
    const hours = parseInt(hourPart, 10);
    const minutes = parseInt(minutePart, 10);
    const valid = typeof hourPart === 'string' &&
        typeof minutePart === 'string' &&
        hourPart.length === 2 &&
        minutePart.length === 2 &&
        hours >= 0 &&
        hours <= 23 &&
        minutes >= 0 &&
        minutes <= 59;
    return valid;
};
export const timeField = ({ invalidMsg } = {
    invalidMsg: 'Invalid Time',
}) => z.string().refine((value) => {
    // Only validate if the value is not empty, this allows to use additional validators to further refine it
    if (value === '') {
        return true;
    }
    return validateMaskHHmm(value);
}, {
    message: invalidMsg,
});
// This has to be done vs using the builtin z.string().datetime() because it expects to have a timezone specifier which is not provided from HTML datetime-local input
export const noFutureDateTime = ({ invalidMsg } = {
    invalidMsg: 'Invalid Date',
}) => {
    return z
        .string()
        .refine((val) => !isNaN(new Date(val).getTime()), { message: invalidMsg })
        .refine((val) => {
        const now = Date.now();
        const date = new Date(val);
        // MUI date picker returns the date at 00:00:00, so we need to add a day to it to make sure it's not in the future
        date.setDate(date.getDate());
        const dateTime = date.getTime();
        return dateTime < now;
    }, {
        message: 'The date cannot be in the future.',
    });
};
// TODO: This is global and should be called only once, we should probably do it in the webapp package
export const customErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_type) {
        if ((issue.expected === 'object' && issue.received === 'null') ||
            issue.received === 'undefined') {
            return { message: `This field is required` };
        }
    }
    return { message: ctx.defaultError };
};
export const requiredString = z.string().trim().min(1, 'Please fill out this field');
