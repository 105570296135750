import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export type ItemType = {
  id: string;
  label: string;
};

type SelectCheckboxProps = {
  label: string;
  values: ItemType[];
  onSelect: (onSelect: string[]) => void;
  initState?: string[];
};

function SelectCheckbox({ label, values, onSelect, initState = [] }: SelectCheckboxProps) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    if (initState) {
      setSelectedItems(initState);
    }
  }, [initState]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'string') {
      return;
    }

    setSelectedItems(value);
    onSelect(value);
  };

  const getSelectedLabels = (selected: string[]) => {
    let results = selected.map((sel) => values.find((value) => value.id === sel)?.label).join(', ');

    if (selected.length === values.length || selected.length === 0 || values[0].id === 'all') {
      results = 'All';
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ color: 'rgba(0, 0, 0, .6)', paddingRight: '5px' }}>{label}</Box>
        <Box
          sx={{
            paddingRight: '5px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: '500',
            fontSize: '14px',
          }}
        >
          {results}
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <FormControl
        sx={{
          width: 200,
          '.MuiInputBase-root': {
            borderRadius: '4px',
            fontSize: '14px',
            height: '32px',
          },
          '.MuiInputBase-root:before': {
            borderBottom: 'none !important',
          },
          '.MuiInputBase-root:after': {
            borderBottom: 'none !important',
          },
          '.MuiSelect-select': {
            padding: 0,
            paddingLeft: 1,
          },
        }}
        size="small"
      >
        <Select
          sx={{ height: '36px' }}
          id="select-checkbox"
          multiple
          displayEmpty
          value={selectedItems}
          onChange={handleChange}
          input={<FilledInput sx={{ backgroundColor: 'rgba(0, 0, 0, 0.06)', borderRadius: 0.5 }} />}
          renderValue={getSelectedLabels}
          MenuProps={MenuProps}
        >
          {values.map(({ id, label }) => (
            <MenuItem key={id} value={id}>
              <Checkbox checked={selectedItems.some((item) => item === id)} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectCheckbox;
