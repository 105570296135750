import { Box, Paper, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { Page } from 'react-pdf';
import { useElementSize } from 'usehooks-ts';

import { NavigationControls } from '~/components/documentPreview/NavigationControls';
import { UploadDocumentType } from '~/components/uploadDocument/AddManuallyDropdown';

import type { LiftAIDocumentUntagged } from '../uploadDocument/UploadDocumentDialogFlow';
import Outline from './Outline';
import ZoomControls from './ZoomControls';

interface DocumentPreviewProps {
  documents: (LiftAIDocumentUntagged & { documentType: UploadDocumentType })[];
  currentPage: number;
  containerHeight: number;
}

const heightRatio = 0.773; // Assuming Letter dimensions, we may want to make this based on the modal in the future
const outlineWidth = 60;

// interface ILiftAIPDFPage extends PageProps {
//   pdf: pdfjs.PDFDocumentProxy;
// }
// // Hack to get access to a missing property
// const LiftAIPDFPage = Page as unknown as React.FC<ILiftAIPDFPage>;

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  documents,
  currentPage,
  containerHeight: parentContainerHeight,
}) => {
  const theme = useTheme();
  const [scale, setScale] = useState(1.1);
  const [pdfContainerRef, { height: pdfContainerHeight }] = useElementSize();

  const paddingAdjustment = parseFloat(theme.spacing(1)) * 2;
  // Calculate the PDF width while respecting the container height so that we don't overflow
  const pdfHeight = Math.min(pdfContainerHeight ?? 0, parentContainerHeight) - paddingAdjustment;
  const pdfWidth = pdfHeight * heightRatio;

  const totalPages = useMemo(() => documents.length, [documents.length]);
  const document = useMemo(() => documents[currentPage - 1], [currentPage, documents]);
  const [page, setPage] = useState(currentPage);

  return (
    <Box display="flex" height="100%">
      {document.fileType === 'pdf' ? (
        <>
          <Box flex={`0 0 ${outlineWidth + paddingAdjustment * 2}px`} px={1} overflow="scroll">
            {<Outline pdf={document.file} currentPage={currentPage} width={outlineWidth} />}
          </Box>
          <Box flex="1 1 auto" minWidth={0} px={1} ref={pdfContainerRef}>
            <Paper
              elevation={3}
              sx={{
                position: 'relative',
                flex: 1,
                margin: '0 auto',
                overflow: 'auto',
                height: pdfHeight,
                maxWidth: pdfWidth,
              }}
            >
              {document.documentType !== UploadDocumentType.initial && (
                <NavigationControls
                  type={documents[page - 1].documentType}
                  defaultPage={currentPage}
                  numberOfPages={totalPages}
                  onNavigationChange={(page) => {
                    setPage(page);
                    setScale(1.0);
                  }}
                />
              )}
              <Page
                pdf={document.file}
                pageNumber={
                  document.documentType !== UploadDocumentType.initial ? page : currentPage
                }
                width={pdfWidth}
                scale={scale}
                renderAnnotationLayer={false}
              />
              <ZoomControls scale={scale} onSetZoomLevel={setScale} />
            </Paper>
          </Box>
        </>
      ) : (
        <>Not Implmented</>
      )}
    </Box>
  );
};

export default DocumentPreview;
