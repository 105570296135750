import z from 'zod';
import { baseLinkedAttachmentSchema } from './attachment';
import { carDisplaySchema } from './car';
import { invoiceDisplaySchema } from './invoice';
import { metadataSchema } from './metadata';
import { propertyDisplaySchema } from './property';
import { providerDisplaySchema } from './provider';
import { customErrorMap, noFutureDateTime, requiredString, timeField, } from './utils/zodHelpers';
export const ticketAttachmentSchema = baseLinkedAttachmentSchema;
/**
 *  Entry Type -  use options from the type table
 *  [Regular Callback (type callback), Emergency/Entrapment (type callback), Maintenance Visit, Deferred Callback (type callback)]
 *
 */
export const problemTypeOptions = [
    'Brake',
    'Car Controller',
    'Controller',
    'Cop',
    'Doors',
    'EMS',
    'Escalator Combplate',
    'Fan',
    'Fixture',
    'Governor',
    'Guide Rails',
    'Hall Fixture',
    'Hoistway Switch',
    'Inductor',
    'Leveling Switch',
    'Limit Switch',
    'Load Weighing',
    'Machine/Motor',
    'Other',
    'Phone',
    'Pit Switch',
    'ROA',
    'Roller Guides',
    'Ropes',
    'Selector',
    'Sheaves',
    'Switches',
    'Transducer',
    'Traveling Cable',
];
export const EntryType = {
    Regular: 'regular',
    Emergency: 'emergency',
    Deferred: 'deferred',
    Visit: 'visit',
    Modernization: 'modernization',
    Repair: 'repair',
    Inspection: 'inspection',
};
export const CallBackType = {
    Regular: 'regular',
    Emergency: 'emergency',
    Deferred: 'deferred',
};
export const MaintenanceType = {
    Visit: 'visit',
    Modernization: 'modernization',
    Repair: 'repair',
    Inspection: 'inspection',
};
export const entryTypeOptions = [
    [CallBackType.Regular, 'Regular Callback'],
    [CallBackType.Emergency, 'Emergency/Entrapment'],
    [CallBackType.Deferred, 'Deferred Callback'],
    [MaintenanceType.Visit, 'Maintenance Visit'],
    [MaintenanceType.Modernization, 'Modernization'],
    [MaintenanceType.Repair, 'Repair'],
    [MaintenanceType.Inspection, 'Inspection'],
];
export const callbackToLabelMap = new Map(entryTypeOptions);
export const entryTypeOptionsValues = [
    [callbackToLabelMap.get(CallBackType.Regular), CallBackType.Regular],
    [callbackToLabelMap.get(CallBackType.Emergency), CallBackType.Emergency],
    [callbackToLabelMap.get(CallBackType.Deferred), CallBackType.Deferred],
    [callbackToLabelMap.get(MaintenanceType.Visit), MaintenanceType.Visit],
    [callbackToLabelMap.get(MaintenanceType.Modernization), MaintenanceType.Modernization],
    [callbackToLabelMap.get(MaintenanceType.Repair), MaintenanceType.Repair],
    [callbackToLabelMap.get(MaintenanceType.Inspection), MaintenanceType.Inspection],
];
export const labelToCallbackMap = new Map(entryTypeOptionsValues);
export function isOfCallBackType(key) {
    return callbackTypeValues.some((val) => val === key);
}
export function ticketIsCallback(ticket) {
    return isOfCallBackType(ticket.entryType);
}
export const getWorkPerformedLabel = (entryType) => {
    const correctiveActionLabel = 'Corrective Action';
    const workPerformedLabel = 'Work Performed';
    return isOfCallBackType(entryType) ? correctiveActionLabel : workPerformedLabel;
};
export const callbackTypeValues = Object.values(CallBackType);
export const maintenanceTypeValues = Object.values(MaintenanceType);
export const entryTypeValues = [
    ...callbackTypeValues,
    ...maintenanceTypeValues,
];
z.setErrorMap(customErrorMap);
// End custom validators
const ticketSchemaBase = z.object({
    id: z.string(),
    number: requiredString.max(20),
    entryType: z.enum(entryTypeValues),
    property: propertyDisplaySchema,
    carsServiced: z.array(carDisplaySchema),
    serviceProviderGroup: providerDisplaySchema.optional(),
    startedTime: noFutureDateTime(),
    finishedTime: noFutureDateTime(),
    attachments: z.array(ticketAttachmentSchema).default([]),
    timeOnSiteRegularHours: timeField(),
    travelTimeRegularHours: timeField(),
    timeOnSiteOvertime: timeField(),
    travelTimeOvertime: timeField(),
    countTowardsKpis: z.boolean(),
    equipmentFailure: z.boolean().nullable().default(null),
    notes: z.string().optional(),
    workPerformed: requiredString, // callback -> corrective action | maintenance -> work performed
    metadata: metadataSchema.array().optional(),
    autoUpload: z.boolean().default(false),
    problemType: z.enum(problemTypeOptions).optional(),
});
export const isTicketAutoUpload = (t) => {
    return t.autoUpload;
};
const callbackTicketSchema = ticketSchemaBase.extend({
    problemDescription: requiredString,
    entryType: z.enum(callbackTypeValues),
    invoice: invoiceDisplaySchema.nullable(),
    /**
     * `callTime` si actually a date, at least is expected to be a date by the API,
     * so empty strings are a problem. To address this, if the string is empty we change
     * the value to be null since the API allows it.
     * */
    callTime: z
        .string()
        .nullable()
        .transform((val) => (val !== null && val.length > 0 ? val : null)),
});
const maintenanceTicketSchema = ticketSchemaBase.extend({
    invoice: invoiceDisplaySchema.nullable(),
    entryType: z.enum(maintenanceTypeValues),
});
export const ticketSchema = z.discriminatedUnion('entryType', [
    maintenanceTicketSchema,
    callbackTicketSchema,
]);
export const DisabledFieldsSchema = z.object({
    number: z.boolean().optional(),
    entryType: z.boolean().optional(),
    propertyId: z.boolean().optional(),
    carIds: z.boolean().optional(),
    invoiceId: z.boolean().optional(),
    workPerformed: z.boolean().optional(),
    callTime: z.boolean().optional(),
    startedTime: z.boolean().optional(),
    finishedTime: z.boolean().optional(),
    problemDescription: z.boolean().optional(),
    countTowardsKpis: z.boolean().optional(),
    equipmentFailure: z.boolean().nullable().default(null),
    notes: z.boolean().optional(),
    timeOnSiteRegularHours: z.boolean().optional(),
    travelTimeRegularHours: z.boolean().optional(),
    timeOnSiteOvertime: z.boolean().optional(),
    travelTimeOvertime: z.boolean().optional(),
    problemType: z.boolean().optional(),
});
const dtoFields = {
    id: z.string().optional(),
    propertyId: z.string(),
    invoiceId: z.string().nullable(),
    carIds: z.array(z.string()),
};
const omittedFieldsForForm = {
    property: true,
    carsServiced: true,
    serviceProviderGroup: true,
    invoice: true,
    attachments: true,
};
export const resolveTimeLabels = (entryType) => {
    if (entryType === EntryType.Regular || entryType === EntryType.Emergency) {
        return {
            startedLabel: 'Response Time',
            finishedLabel: 'Resolution Time',
        };
    }
    return {
        startedLabel: 'Arrival Time',
        finishedLabel: 'Departure Time',
    };
};
export const ticketDtoSchema = z
    .discriminatedUnion('entryType', [
    maintenanceTicketSchema.extend(dtoFields).omit(omittedFieldsForForm),
    callbackTicketSchema.extend(dtoFields).omit(omittedFieldsForForm),
]) // TODO: Put this on ticketSchema as well
    // IMPORTANT NOTE: This superRefine gets triggered until the schema has no errors
    .superRefine((val, ctx) => {
    if (val.entryType === MaintenanceType.Visit ||
        val.entryType === MaintenanceType.Repair ||
        val.entryType === MaintenanceType.Inspection ||
        val.entryType === MaintenanceType.Modernization) {
        const started = new Date(val.startedTime);
        const finished = new Date(val.finishedTime);
        const { startedLabel, finishedLabel } = resolveTimeLabels(val.entryType);
        if (started >= finished) {
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_date,
                message: `${startedLabel} must be before ${finishedLabel}`,
                path: ['startedTime'],
            });
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_date,
                message: `${finishedLabel} must be after ${startedLabel}`,
                path: ['finishedTime'],
            });
        }
    }
});
