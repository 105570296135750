import type { Arguments } from 'swr';

export const getMutatorForType =
  (type: string) => (idToRevalidate: string | null | undefined) => (key: Arguments) => {
    console.log('type', type);
    console.log('idToRevalidate', idToRevalidate);

    if (!Array.isArray(key)) return false;

    const [keyType, idOrParams] = key;
    console.log('keyType', keyType);
    console.log('keyIdOrParams', idOrParams);

    if (keyType !== type) return false;

    // If the key is just `type`, then we want to revalidate: this is the
    // list of all types.
    if (key.length === 1) return true;

    // If the key is `type` and params, then we want to revalidate: this is
    // the list of all types with the search params.
    if (typeof idOrParams === 'object') return true;

    // If the key has an ID that matches the one we want to revalidate, then
    // Notably, this also revalidates its Tickets, Notes, and Attachments.
    if (idOrParams === idToRevalidate) return true;

    // Otherwise, we don't want to revalidate.
    return false;
  };

export const getInvoiceMutator = getMutatorForType('invoice');
export const getTicketMutator = getMutatorForType('ticket');
