import type { IMenuItemProps } from '~/components/SplitButtonSelect';
import SplitButtonSelect from '~/components/SplitButtonSelect';

// TODO: Does this declaration make sense here?
export enum UploadDocumentType {
  initial = 'Initial',
  invoice = 'Invoice',
  invoiceContinued = 'Invoice (Continued)',
  ticket = 'Ticket',
  ticketContinued = 'Ticket (Continued)',
  supportingData = 'Supporting Data',
  proposal = 'Proposal',
  proposalContinued = 'Proposal (Continued)',
}

interface IUploadDocumentDialogProps {
  onTypeSelected: (type: UploadDocumentType) => void;
}

const menuItems: IMenuItemProps[] = [
  {
    label: 'Invoice',
    value: UploadDocumentType.invoice,
  },
  {
    label: 'Ticket',
    value: UploadDocumentType.ticket,
  },
  {
    label: 'Proposal',
    value: UploadDocumentType.proposal,
  },
];

export default function AddManuallyDropdown({ onTypeSelected }: IUploadDocumentDialogProps) {
  return (
    <div>
      <SplitButtonSelect title="Add Manually" options={menuItems} onTypeSelected={onTypeSelected} />
    </div>
  );
}
