import type { PortfolioClient } from '@liftai/asset-management-types';

import { notEmpty } from '~/utils/types';

const getRegionsFromPortfolio = (portfoliosFromApi: PortfolioClient[]) => {
  const mappedRegions = portfoliosFromApi.flatMap((portfolio) => {
    const { properties } = portfolio;
    if (!properties) {
      return [];
    }
    const regions = properties.map((property) => property.region);

    return regions;
  });

  return [...new Set(mappedRegions.filter(notEmpty))];
};

export default getRegionsFromPortfolio;
