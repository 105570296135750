import type { Attachment } from '@liftai/asset-management-types';
import React, { useCallback, useState } from 'react';

import type { IDragDropZoneProps } from '~/components/DragDropZone';
import DragDropZone from '~/components/DragDropZone';
import { getApiClient } from '~/utils/api';

interface IAttachmentUploaderProps
  extends Pick<
    IDragDropZoneProps,
    'acceptedFileTypesLabel' | 'fileSizeLimit' | 'acceptedFileTypes'
  > {
  onFilesUploaded: (attachment: Attachment[]) => void;
}

const AttachmentUploader: React.FC<IAttachmentUploaderProps> = ({ onFilesUploaded, ...rest }) => {
  const [isUploading, setIsUploading] = useState(false);
  const handleFileUpload = useCallback(
    async (files: File[]) => {
      if (files.length < 1) {
        return;
      }

      const file = files[0];

      setIsUploading(true);

      try {
        const apiClient = getApiClient();
        // TODO: Make this dynamic when we allow for other file types
        const attachment = await apiClient.attachments.upload({
          file,
          name: file.name,
          file_type: 'pdf',
        });
        onFilesUploaded([attachment]);
      } finally {
        setIsUploading(false);
      }
    },
    [onFilesUploaded],
  );

  return <DragDropZone {...rest} onFileSelected={handleFileUpload} loading={isUploading} />;
};

export default AttachmentUploader;
