import type { Metadata } from '@liftai/asset-management-types';

interface EntityWithMetadata {
  metadata?: Metadata[];
}

const hasExternalSourceMetadata = (entity: EntityWithMetadata): boolean => {
  const { metadata } = entity;
  if (!metadata) {
    return false;
  }

  return metadata.some((meta) => meta.key === 'external_source');
};

export default hasExternalSourceMetadata;
