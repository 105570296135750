import type { InvoiceForm, InvoiceKindKey } from '@liftai/asset-management-types';
import { InvoiceKind } from '@liftai/asset-management-types';
import { Dialog, DialogContent, useTheme } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { UploadDocumentType } from '~/components/uploadDocument/AddManuallyDropdown';

import DialogBackNextActions from '../DialogBackNextActions';
import DialogTitleWithCloseButton from '../DialogTitleWithCloseButton';
import { useInvoiceForm } from '../uploadDocument/InvoiceForm';

interface IInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data?: InvoiceForm) => void;
  isEdit?: boolean;
  defaultValues?: InvoiceForm;
  kind: InvoiceKindKey;
}

const InvoiceDialog = ({
  open,
  onClose,
  onSubmit,
  isEdit,
  defaultValues,
  kind,
}: IInvoiceDialogProps) => {
  const theme = useTheme();
  const documentType =
    kind === InvoiceKind.Proposal ? UploadDocumentType.proposal : UploadDocumentType.invoice;
  const { Fields: InvoiceFields, Form: InvoiceForm, hookForm } = useInvoiceForm();
  const {
    formState: { isSubmitting },
  } = hookForm;

  const title = `${isEdit ? 'Edit' : 'Add'} ${documentType}`;
  const actionBtns = {
    next: { label: isSubmitting ? 'Saving' : 'Save', enabled: !isSubmitting },
  };

  return (
    <FormProvider {...hookForm}>
      <InvoiceForm onSubmit={onSubmit} initialData={defaultValues}>
        <Dialog open={open} onClose={onClose} maxWidth="sm" disablePortal>
          <DialogTitleWithCloseButton onClose={onClose} title={title} />
          <DialogContent
            sx={{ padding: theme.spacing(2), paddingTop: 0, overflow: 'scroll', height: '100%' }}
          >
            <InvoiceFields />
          </DialogContent>
          <DialogBackNextActions onBack={onClose} actionBtns={actionBtns} />
        </Dialog>
      </InvoiceForm>
    </FormProvider>
  );
};

export default InvoiceDialog;
