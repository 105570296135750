import { EntryTypeKey, InvoiceTypeKey } from '@liftai/asset-management-types';

export const buildUrlForTickets = ({
  startDate,
  endDate,
  entryType,
  property,
  portfolios,
  number,
  selectedInvoiceId,
  ticketId,
}: {
  startDate: string;
  endDate: string;
  property: string;
  entryType?: EntryTypeKey[];
  portfolios?: string[];
  number?: string;
  selectedInvoiceId?: string;
  ticketId?: string;
}): string => {
  const ticketSearchParams = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
    property: property,
  });

  if (number) {
    ticketSearchParams.append('number', number);
  }

  if (entryType) {
    for (const type of entryType) {
      ticketSearchParams.append('entry_type', type);
    }
  }

  if (selectedInvoiceId) {
    ticketSearchParams.append('selectedInvoiceId', selectedInvoiceId);
  }

  if (portfolios) {
    for (const portfolio of portfolios) {
      ticketSearchParams.append('portfolio', portfolio);
    }
  }

  if (ticketId) {
    ticketSearchParams.append('ticketId', ticketId);
  }

  return `/tickets?${ticketSearchParams}`;
};

export const buildUrlForInvoices = ({
  startDate,
  endDate,
  type,
  property,
  portfolios,
  kind,
  status,
  invoiceId,
}: {
  startDate: string;
  endDate: string;
  property?: string;
  type?: InvoiceTypeKey[];
  portfolios?: string[];
  kind?: string;
  status?: string[];
  invoiceId?: string;
}): string => {
  const params = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
  });

  if (property) {
    params.append('property', property);
  }

  if (type) {
    for (const t of type) {
      params.append('invoice_type', t);
    }
  }

  if (kind) {
    params.append('invoice_kind', kind);
  }

  if (status) {
    for (const s of status) {
      params.append('invoice_status', s);
    }
  }

  if (portfolios) {
    for (const portfolio of portfolios) {
      params.append('portfolio', portfolio);
    }
  }

  if (invoiceId) {
    params.append('selectedInvoiceId', invoiceId);
  }

  return `/spending?${params}`;
};
