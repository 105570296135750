import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import type { SxProps } from '@mui/material';
import { Button } from '@mui/material';
import type { OptionsObject } from 'notistack';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export enum ActionEnum {
  Get = 'fetched',
  Create = 'created',
  Update = 'updated',
  Delete = 'deleted',
  StatusChange = 'status changed',
  Fail = 'failed',
}

// autoHideDurationMs
const autoHideDuration = 1500;

const iconStyles: SxProps = {
  mr: 1,
};

export const iconVariants = {
  info: <InfoOutlinedIcon sx={iconStyles} />,
  success: <TaskAltIcon sx={iconStyles} />,
  warning: <WarningAmberIcon sx={iconStyles} />,
  error: <ErrorOutlineOutlinedIcon sx={iconStyles} />,
  default: <InfoOutlinedIcon sx={iconStyles} />,
};

const actionMap: Map<ActionEnum | string, string> = new Map([
  [ActionEnum.Create, 'added'],
  [ActionEnum.Update, 'updated'],
  [ActionEnum.Delete, 'deleted'],
  [ActionEnum.Fail, 'action failed'],
]);

const useLAISnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSnackbar = useCallback(
    (message: string, options?: OptionsObject) => {
      const { persist, variant } = options || {};
      const shouldPersist = persist || variant === 'error';
      const hideDuration = shouldPersist ? null : autoHideDuration;
      return enqueueSnackbar(message, {
        ...options,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: hideDuration,
        action: (key) => {
          return (
            <Button color="inherit" size="medium" onClick={() => closeSnackbar(key)}>
              DISMISS
            </Button>
          );
        },
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  const showEntityActionSnackbar = useCallback(
    (
      entityOptions: {
        name: string;
        id?: number | string;
        action: ActionEnum;
      },
      options?: OptionsObject,
    ) => {
      const { name, id, action } = entityOptions;
      const message = [name, id, actionMap.get(action)]
        .filter((p) => p !== undefined && p !== null)
        .join(' ');
      showSnackbar(message, options);
    },
    [showSnackbar],
  );

  return {
    showSnackbar,
    showEntityActionSnackbar,
  };
};

export default useLAISnackbar;
