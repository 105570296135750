import { Box } from '@mui/material';

interface EllipsisTextProps {
  children: React.ReactNode;
  chars?: number;
}

export default function EllipsisText({ children, chars = 10 }: EllipsisTextProps) {
  return (
    <Box
      sx={{
        maxWidth: `${chars}ch`,
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Box>
  );
}
