import { Ticket } from '@liftai/asset-management-types';
import useSWR, { type Fetcher } from 'swr';

import { getApiClient } from '~/utils/api';

export const ticketsByInvoiceFetcher: Fetcher<
  Ticket[],
  readonly ['invoice', invoiceId: string, 'tickets']
> = async ([_, invoiceId]) => {
  const apiClient = getApiClient();
  const response = await apiClient.tickets.getByInvoiceId(invoiceId);

  return response;
};

export default function useInvoiceTickets(invoiceId: string | null) {
  const {
    data,
    error,
    isLoading: loading,
  } = useSWR(
    invoiceId ? (['invoice', invoiceId, 'tickets'] as const) : null,
    ticketsByInvoiceFetcher,
  );

  return { tickets: data, error, loading };
}
