import type { SxProps, Theme } from '@mui/material';
import { Box, Tab, Tabs } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { tabDef } from '~/components/common/tabs/definition';
import { selectedColor } from '~/theme';

/** tabsOptions customization options for the `Tabs` component*/
export type TabsOptions = {
  /** styles that can be applied */
  styles?: {
    /**
     * styles that applies to the surrounded `<Box>` as follows
     * @example <Box sx={opts.styles?.box}><Tabs>...</Tabs></Box>
     */
    box?: SxProps<Theme>;
    /**
     * styles that applies to the `<Tabs>` components
     * @example <Tabs sx={opts.styles?.tabs ?? { backgroundColor: selectedColor }}>...</Tabs>
     * @default { backgroundColor: selectedColor }
     */
    tabs?: SxProps<Theme>;
  };
  /**
   * list of tab labels to disable
   */
  disable?: string[];
  /**
   * list of tab labels to override
   */
  overrideLabels?: {
    [key: string]: string;
  };
};

/**
 * A hook which accepts a list of tab labels and returns a `<Tabs>` instance
 * pre-configured with them and a `selectedTab` state variable which reflects what
 * the currently selected tab is.
 *
 * The hook also returns a `tabs` object which is a map of the tab labels to a
 * `TabDefinition` object representing them. This object contains helpful,
 * pre-computed IDs for the tab and its associated panel that can be used to easily
 * associate the two with `aria-controls` and `aria-labelledby`.
 */
const useTabs = (
  tabLabels: string[],
  defaultSelected?: (typeof tabLabels)[number],
  opts: TabsOptions = {},
) => {
  const tabDefs = useMemo(() => tabLabels.map(tabDef), [tabLabels]);
  const [selectedTab, setSelectedTab] = useState(
    (defaultSelected && tabDefs[tabLabels.indexOf(defaultSelected)].tabId) ?? tabDefs[0].tabId,
  );

  return {
    selectedTab,
    tabStrip: useMemo(() => {
      return (
        <Box sx={opts.styles?.box}>
          <Tabs
            sx={{ backgroundColor: selectedColor, ...opts.styles?.tabs }}
            value={selectedTab}
            onChange={(_event, newTab) => setSelectedTab(newTab)}
          >
            {tabDefs.map(({ tabId: key, tabId: id, tabId: value, label, panelId }) => (
              <Tab
                key={key}
                {...{ id, value, label: opts.overrideLabels?.[label] ?? label }}
                aria-controls={panelId}
                disabled={opts.disable?.includes(label)}
              />
            ))}
          </Tabs>
        </Box>
      );
    }, [selectedTab, tabDefs, opts]),
    tabs: useMemo(() => Object.fromEntries(tabDefs.map((tab) => [tab.label, tab])), [tabDefs]),
    selectTab: useCallback(
      (tabName: string) => {
        setSelectedTab(tabDefs[tabLabels.indexOf(tabName)].tabId);
      },
      [tabDefs, tabLabels],
    ),
  };
};

export default useTabs;
