import z from 'zod';
export const PortfolioKPIsSchema = z.object({
    avgCallResponseTimeSecs: z.number().nullable(),
    avgEmergencyCallResponseTimeSecs: z.number().nullable(),
    callbackRate: z.number(),
    callbacks: z.number(),
    emergencyCallbackRate: z.number(),
    emergencyCalls: z.number(),
    maintenanceVisit: z.number(),
    maintenanceCompletion: z.number(),
    maintenanceTotalAmount: z.number().nullable(),
    maintenanceSecs: z.number().nullable(),
    onTimeResponse: z.number().nullable(),
    spending: z.number().nullable(),
    uptime: z.number().nullable(),
    devices: z.number(),
    callbackMedianTime: z.number().nullable(),
});
export const portfolioKPIsByPropertySchema = PortfolioKPIsSchema.extend({
    property: z.string(),
});
export const portfolioKPIsByDateSchema = PortfolioKPIsSchema.extend({
    date: z.string(),
});
