import type { SelectChangeEvent } from '@mui/material';
import { MenuItem, Select } from '@mui/material';

import type { UploadDocumentType } from './AddManuallyDropdown';

interface SelectDocumentTypeDropdownProps {
  documentTypes: UploadDocumentType[];
  onChange: (value: UploadDocumentType) => void;
  value?: string | null;
}

const SelectDocumentTypeDropdown: React.FC<SelectDocumentTypeDropdownProps> = ({
  documentTypes,
  onChange,
  value = null,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value as UploadDocumentType);
  };

  return (
    <Select value={value || ''} variant="outlined" fullWidth onChange={handleChange}>
      {documentTypes.map((documentType) => (
        <MenuItem key={documentType} value={documentType}>
          {documentType}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectDocumentTypeDropdown;
