const TAB_PREFIX = `tab-`;
const TAB_PANEL_PREFIX = `tabpanel-`;

export interface TabDefinition {
  label: string;
  tabId: string;
  panelId: string;
}

export const tabDef = (label: string): TabDefinition => {
  const id = label.toLowerCase().replace(/ /g, '');
  return { label, tabId: `${TAB_PREFIX}${id}`, panelId: `${TAB_PANEL_PREFIX}${id}` };
};
