import { createContext, useContext } from 'react';

import type { ItemType } from '~/components/common/SelectCheckbox';

export type GlobalFilterContextType = {
  portfolios: ItemType[];
  serviceProviders: ItemType[];
  regions: ItemType[];
};

const initialContextState: GlobalFilterContextType = {
  portfolios: [],
  serviceProviders: [],
  regions: [],
};

export const useGlobalFilterContext = () => {
  const context = useContext(GlobalFilterContext);
  if (!context) {
    //throw new Error('useGlobalFilterContext must be used within a GlobalFilterContextProvider');
    console.error('useGlobalFilterContext must be used within a GlobalFilterContextProvider');
  }
  return context;
};

export const getFilters = () => {};

// const GlobalFilterContext = createContext<GlobalFilterContextType | null>(null);
const GlobalFilterContext = createContext<GlobalFilterContextType>(initialContextState);

export default GlobalFilterContext;
