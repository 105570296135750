import { Box, Typography } from '@mui/material';

interface AppBarButtonProps {
  icon?: React.ReactNode;
  label: React.ReactNode;
  href?: string;
  active?: boolean;
  disabled?: boolean;
  isSidebarButton?: boolean;
  onClick?: React.MouseEventHandler;
}

export default function AppBarButton({
  icon,
  label,
  href,
  active = false,
  disabled = false,
  isSidebarButton = false,
  onClick,
}: AppBarButtonProps) {
  const HOVER_COLOR = 'primary.light';
  const ACTIVE_COLOR = 'primary.main';

  return (
    <Box
      data-testid="app-bar-button"
      data-active={active}
      data-disabled={disabled}
      display="flex"
      flexDirection="column"
      px={1}
      justifyItems="stretch"
      alignItems="center"
      onClick={onClick}
      height={(theme) => theme.spacing(6)}
      {...(isSidebarButton && { width: (theme) => theme.spacing(10) })}
      {...(!disabled && { component: 'a', href })}
      sx={{
        cursor: 'pointer',
        color: 'GrayText',
        textDecoration: 'none',

        // When disabled, the cursor should indicate this.
        '&[data-disabled="true"]': { cursor: 'not-allowed' },
        '&[data-disabled="false"]:hover': { backgroundColor: HOVER_COLOR },

        // Turn the text and icon the `ACTIVE_COLOR` when the `AppBarButton` is
        // considered `active`.
        '&[data-active="true"]': { color: ACTIVE_COLOR, backgroundColor: HOVER_COLOR },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>{icon}</Box>
      <Typography component="span" sx={{ typography: 'caption' }}>
        {label}
      </Typography>
    </Box>
  );
}
