import { API_UserGroupDetailResponse, User } from '@liftai/asset-management-types';
import * as Sentry from '@sentry/react';
import { init as initCommandBar } from 'commandbar';
import { useCallback, useEffect } from 'react';
import type { LoaderFunctionArgs } from 'react-router-dom';
import { useLoaderData, useMatches, useSearchParams } from 'react-router-dom';

import { UserContext } from '~/auth/userContext';
import type { ItemType } from '~/components/common/SelectCheckbox';
import SideBarLayout from '~/components/common/SideBarLayout';
import App from '~/components/global/App';
import { selectedInvoiceIdKey, selectedTabSearchParam } from '~/components/global/constants';
import { InvoiceDetailDrawerFlow } from '~/components/invoices/InvoiceDetailDrawerContext';
import { UploadDocumentDialogFlow } from '~/components/uploadDocument/UploadDocumentDialogFlow';
import GlobalFilterContext from '~/contexts/globalFilterContext';
import usePendingInvoices from '~/data/hooks/usePendingInvoices';
import { useLAICommandBar } from '~/hooks/useLAICommandBar';
import { getPortfoliosItems } from '~/lib/portfolio/getPortfoliosFromProperties';
import getRegionsFromPortfolio from '~/lib/portfolio/getRegionsFromPortfolio';
import { getApiClient, json, liftAiAuth } from '~/utils/api';

const {
  VITE_COMMANDBAR_ORG_ID: COMMANDBAR_ORG_ID,
  VITE_GBP_CURRENCY_GROUP_NAME_REGEX: GBP_CURRENCY_GROUP_NAME_REGEX,
} = import.meta.env;

if (COMMANDBAR_ORG_ID) {
  initCommandBar(COMMANDBAR_ORG_ID);
}

interface LoaderData {
  user: { email?: string; uid: string };
  currentUser: User;
  currencyCode: 'GBP' | 'USD';
  userGroup: API_UserGroupDetailResponse;
  portfoliosToFilter: ItemType[];
  serviceProvidersToFilter: ItemType[];
  regionsToFilter: ItemType[];
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const { requireUser } = liftAiAuth();
  const apiClient = getApiClient();
  const { email, uid } = await requireUser(`${url.pathname}?${url.searchParams}`);

  const [currentUser, portfoliosFromApi, serviceProvidersFromApi] = await Promise.all([
    apiClient.users.me(),
    apiClient.portfolios.getAll(),
    apiClient.providers.getAll(),
  ]);

  let userGroup;
  if (currentUser.clientGroup) {
    userGroup = await apiClient.clients.get(currentUser.clientGroup);
  } else if (currentUser.consultantGroup) {
    userGroup = await apiClient.consultants.get(currentUser.consultantGroup);
  } else {
    throw new Error('User does not belong to a group');
  }

  // Create the data for the portfolios filter
  const portfoliosToFilter = getPortfoliosItems(portfoliosFromApi);

  // Create the data for the service providers filter
  const serviceProvidersToFilter = serviceProvidersFromApi.map((provider) => ({
    id: provider.id,
    label: provider.name,
  }));

  // Get the regions from the portfolios
  const regionsFromPortfolios = getRegionsFromPortfolio(portfoliosFromApi);
  const regionsToFilter = regionsFromPortfolios.map((region) => ({
    id: region,
    label: region,
  }));

  const response: LoaderData = {
    user: { email, uid },
    currentUser,
    userGroup: userGroup,
    currencyCode: GBP_CURRENCY_GROUP_NAME_REGEX
      ? userGroup.name.match(new RegExp(GBP_CURRENCY_GROUP_NAME_REGEX))
        ? 'GBP'
        : 'USD'
      : 'USD',
    portfoliosToFilter,
    serviceProvidersToFilter,
    regionsToFilter,
  };

  return json(response);
};

const useTopLevelRouteMatch = () => {
  const [, , topLevelMatch] = useMatches();
  return (pathname: string) => topLevelMatch?.pathname === pathname;
};

export function AppShell() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pendingInvoices } = usePendingInvoices();
  const {
    user,
    currentUser,
    userGroup,
    portfoliosToFilter,
    currencyCode,
    serviceProvidersToFilter,
    regionsToFilter,
  } = useLoaderData() as SerializeFrom<typeof loader>;
  const routeMatches = useTopLevelRouteMatch();

  useEffect(() => {
    Sentry.setUser({ email: user.email, id: user.uid });
  }, [user]);

  const { openCommandBar } = useLAICommandBar({ uid: user.uid, role: currentUser.role });

  const onInvoiceSelected = useCallback(
    (invoiceId: string | null) => {
      setSearchParams((searchParams) => {
        if (invoiceId === null) {
          searchParams.delete(selectedInvoiceIdKey);
          searchParams.delete(selectedTabSearchParam);
        } else {
          searchParams.set(selectedInvoiceIdKey, invoiceId);
        }
        return searchParams;
      });
    },
    [setSearchParams],
  );

  return (
    <UserContext.Provider
      value={{
        user,
        userDetails: currentUser,
        userGroup,
        currencyCode,
      }}
    >
      <GlobalFilterContext.Provider
        value={{
          portfolios: portfoliosToFilter,
          serviceProviders: serviceProvidersToFilter,
          regions: regionsToFilter,
        }}
      >
        <InvoiceDetailDrawerFlow
          selectedInvoiceId={searchParams.get(selectedInvoiceIdKey)}
          onInvoiceSelected={onInvoiceSelected}
          pendingInvoices={pendingInvoices}
        >
          <UploadDocumentDialogFlow>
            <SideBarLayout
              numPendingInvoices={pendingInvoices?.length}
              routeMatches={routeMatches}
              openSearch={openCommandBar}
            >
              <App />
            </SideBarLayout>
          </UploadDocumentDialogFlow>
        </InvoiceDetailDrawerFlow>
      </GlobalFilterContext.Provider>
    </UserContext.Provider>
  );
}
