/**
 * A type that represents an event that is fired on an HTML element.
 * @param T the type of the HTML element
 * @returns the event type
 * @example type MyEvent = HTMLElementEvent<HTMLButtonElement>;
 */
export type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
  currentTarget: T;
};

/**
 * This function is used to get the proper return value type.
 *
 * Example:
 * If I use the example below, the inferred returned type could be (string | null | undefined)[],
 * but it should be string[]
 *
 * testArray.filter(item => item)
 * testArray.filter(item => item !== null && item !== undefined)
 *
 * @param value
 * @returns
 */
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}
