import { Link, type LinkProps } from '@mui/material';
import { Link as LinkNavigator } from 'react-router-dom';

export interface LiftAITextLinkProps extends Omit<LinkProps, 'sx' | 'variant'> {
  label: string;
}

/**
 * MUI wrapped Link with standard styling to use
 * throughout the app for consistency.
 * All props available to link except for `sx` and `variant`
 * rest is all the other props that are passed and available in LinkProps
 */
export const LiftAITextLink = ({ label, ...rest }: LiftAITextLinkProps) => {
  return (
    <Link
      sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
      variant="button"
      {...rest}
    >
      {label}
    </Link>
  );
};

type LiftAITextLinkNavigatorProps = {
  label: string;
  href: string;
  variant?: 'button' | 'secondary';
};

export const LiftAITextLinkNavigator = ({
  label,
  href,
  variant = 'button',
  ...rest
}: LiftAITextLinkNavigatorProps) => {
  return (
    <Link
      component={LinkNavigator}
      to={href}
      sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
      variant={variant}
      {...rest}
    >
      {label}
    </Link>
  );
};
