import type {
  CarInProperty,
  InvoiceKindKey,
  PropertyForm,
  Provider,
  User,
} from '@liftai/asset-management-types';
import { invoiceKindToLabelMap, invoiceTypeOptions } from '@liftai/asset-management-types';

import type { FieldDef } from '../form/utils';

interface InvoiceFieldDefOptions {
  properties: PropertyForm[] | undefined;
  consultants: User[] | undefined;
  kind: InvoiceKindKey;
  cars: CarInProperty[] | undefined;
}

export const buildFieldDef = ({ properties, consultants, kind, cars }: InvoiceFieldDefOptions) => {
  const invoiceOrProposal = invoiceKindToLabelMap.get(kind);
  const generalFields: FieldDef[] = [
    { name: 'date', label: 'Date', type: 'date' },
    {
      name: 'number',
      label: `${invoiceOrProposal} #`,
      type: 'text',
    },
    {
      name: 'propertyId',
      label: 'Property',
      type: 'autocomplete',
      options:
        properties?.map((property) => ({
          id: property.id,
          label: property.name,
        })) ?? [],
      loading: properties === undefined,
      gridProps: { xs: 12 },
    },
    {
      name: 'consultantId',
      label: 'Consultant',
      type: 'autocomplete',
      options:
        consultants?.map((consultant) => ({
          id: consultant.id,
          label: consultant.fullName ? consultant.fullName : consultant.email,
        })) ?? [],
      loading: consultants === undefined,
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      options: invoiceTypeOptions.map(([value, label]) => ({ id: value, label })),
    },
    {
      name: 'carIds',
      label: 'Cars Serviced',
      type: 'autocomplete-multiple',
      options: cars
        ? cars.map((car) => ({
            id: car.id,
            label: car.name + (car.ahjId ? ` - ${car.ahjId}` : ''),
          }))
        : [],
      loading: cars === undefined,
    },
    {
      name: 'serviceProviderId',
      label: 'Service Provider',
      type: 'autocomplete',
      options: cars
        ? cars
            ?.reduce((acc, car) => {
              if (car?.contract?.serviceProvider) {
                if (
                  acc.find((provider) => provider.id === car.contract?.serviceProvider.id) ===
                  undefined
                ) {
                  acc.push(car.contract.serviceProvider);
                }
              }
              return acc;
            }, new Array<Provider>())
            .map((provider) => ({
              id: provider.id,
              label: provider.name,
            }))
        : [],
      loading: cars === undefined,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'text-multiline',
      required: false,
      gridProps: { xs: 12 },
    },
  ];

  const itemsFields: FieldDef[] = [
    { name: 'lineItems', label: 'Line Items', type: 'line-items', gridProps: { xs: 12 } },
  ];

  const totalField: FieldDef = {
    name: 'proposedAmount',
    label: `${invoiceOrProposal} Amount`,
    type: 'currency',
  };

  return {
    generalFields,
    itemsFields,
    totalField,
  };
};
