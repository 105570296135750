import { CarInProperty } from '@liftai/asset-management-types';
import useSWR, { type Fetcher } from 'swr';

import { getApiClient } from '~/utils/api';

const carsFetcher: Fetcher<
  CarInProperty[],
  readonly ['property', propertyId: string, 'cars']
> = async ([_, propertyId]) => {
  const apiClient = getApiClient();
  const cars = await apiClient.cars.getByPropertyId({ propertyId });
  return cars satisfies CarInProperty[];
};

export const usePropertyCars = (propertyId: string | null) => {
  const { data: cars, isLoading: loading } = useSWR(
    propertyId ? (['property', propertyId, 'cars'] as const) : null,
    carsFetcher,
  );

  return { cars, loading };
};
