import { isSameMonth, isWithinInterval } from 'date-fns';

import type { SelectedDateRange } from './types';

export const isInSameMonth = (dayA: Date, dayB: Date | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return isSameMonth(dayA, dayB);
};

export const isInRange = (day: Date, range?: SelectedDateRange) => {
  if (!range || !range[0]) return false;
  if (!range[1]) return isInSameMonth(day, range[0]);

  return isWithinInterval(day, {
    start: range[0],
    end: range[1],
  });
};
