import { zodResolver } from '@hookform/resolvers/zod';
import type {
  API_InvoiceFilterResponse,
  API_TicketFilterResponse,
  Ticket,
} from '@liftai/asset-management-types';
import { Grid, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import type { DefaultValues } from 'react-hook-form';
import { useForm, useFormContext } from 'react-hook-form';
import useSWR, { type Fetcher } from 'swr';
import { z } from 'zod';

import { UploadDocumentType } from '~/components/uploadDocument/AddManuallyDropdown';
import type {
  LAIUseFormReturn,
  LiftAIFormProps,
} from '~/components/uploadDocument/SelectDocumentTypeDialog';
import { isLiftAIDocumentPDFUntagged } from '~/components/uploadDocument/UploadDocumentDialogFlow';
import { associateFileToEntity } from '~/components/utils/upload';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { getApiClient } from '~/utils/api';

import type { FieldDef } from '../form/utils';
import { FieldAndCell } from '../form/utils';

export type SupportingDataDto = {
  supportingDataMovingForward?: boolean;
  remainingPages?: number;
  invoiceKind?: string;
  isContinued?: boolean;
} & ({ invoiceId?: string } | { ticketId?: string | null });

export type SupportingDataOnSubmitData = {
  number: string;
  propertyName: string;
};

const addSupportingDataSchema = z.object({
  invoiceId: z.string({
    required_error: 'This field is required',
    invalid_type_error: 'This field is required',
  }),
  ticketId: z.string().nullable(),
});

const getDefaultValues = (): DefaultValues<SupportingDataDto> => {
  return {
    invoiceId: '',
    ticketId: null,
  };
};

export const useSupportingDataForm = (): LAIUseFormReturn<
  SupportingDataDto,
  SupportingDataOnSubmitData
> => {
  const hookForm = useForm<SupportingDataDto>({
    mode: 'all',
    defaultValues: getDefaultValues(),
    resolver: zodResolver(addSupportingDataSchema),
  });

  return {
    hookForm,
    Form: SupportingDataForm,
    Fields: SupportingDataFields,
  };
};

const SupportingDataForm = ({
  children,
  document,
  onSubmit,
  initialData,
}: LiftAIFormProps<SupportingDataDto, SupportingDataOnSubmitData>) => {
  const { showEntityActionSnackbar } = useLAISnackbar();
  const { handleSubmit, reset, watch } = useFormContext<SupportingDataDto>();

  useEffect(() => {
    if (!initialData) {
      return;
    }

    reset({
      ...getDefaultValues(),
      ...(initialData ?? {}),
    });
  }, [initialData, reset]);

  if (!document) {
    console.error('No document provided to SupportingDataForm');
    return <></>;
  }

  const { supportingDataMovingForward, remainingPages, isContinued } = watch();

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit(
        async (data) => {
          const apiClient = getApiClient();

          try {
            if (isLiftAIDocumentPDFUntagged(document)) {
              if ('ticketId' in data && data.ticketId) {
                await associateFileToEntity(document.attachment.id, data.ticketId, 'ticket', {
                  pageNumber: document.pageNum,
                  isPrimary: false,
                  isNextPage: true,
                });
                const ticket: Ticket = await apiClient.tickets.getById(data.ticketId);
                onSubmit({
                  number: ticket.number,
                  propertyName: ticket.property.name,
                });
              } else if ('invoiceId' in data && data.invoiceId) {
                const invoiceId = data.invoiceId;
                if (isContinued) {
                  await associateFileToEntity(document.attachment.id, invoiceId, 'invoice', {
                    pageNumber: document.pageNum,
                    isPrimary: true,
                    isNextPage: true,
                  });
                } else if (supportingDataMovingForward) {
                  const docs = new Array(document.pageNum + (remainingPages ?? 0)).fill(0);
                  // We cannot optimize this with Promise.all as upload order is important
                  for (let i = 0; i < docs.length; i++) {
                    await associateFileToEntity(document.attachment.id, invoiceId, 'invoice', {
                      pageNumber: document.pageNum + i,
                    });
                  }
                } else {
                  await associateFileToEntity(document.attachment.id, invoiceId, 'invoice', {
                    pageNumber: document.pageNum,
                  });
                }

                const invoice = await apiClient.invoices.getById(invoiceId);
                onSubmit({
                  number: invoice.number,
                  propertyName: invoice.property.name,
                });
              }
            }

            showEntityActionSnackbar(
              {
                name: document.documentType ?? 'Document',
                action: ActionEnum.Create,
                id: undefined,
              },
              {
                variant: 'info',
              },
            );
          } catch (err) {
            console.error('err :>> ', err);
          }
        },
        (err) => {
          console.log('err :>> ', err);
        },
      )}
    >
      {children}
    </form>
  );
};

const getForProp = (documentType?: UploadDocumentType) => {
  switch (documentType) {
    case UploadDocumentType.supportingData:
    case UploadDocumentType.invoiceContinued:
    case UploadDocumentType.proposalContinued:
      return 'invoice';
    case UploadDocumentType.ticketContinued:
      return 'ticket';
    default:
      return '';
  }
};

export const ticketOrInvoiceOptionsFetcher: Fetcher<
  API_InvoiceFilterResponse['results'] | API_TicketFilterResponse['results'],
  readonly ['SupportingDataForm.Options', forProp: 'ticket' | 'invoice']
> = async ([_, forProp]) => {
  const apiClient = getApiClient();
  const response = await (forProp === 'ticket'
    ? apiClient.tickets.filter({
        ordering: 'number',
      })
    : apiClient.invoices.filter({
        ordering: 'number',
        variant: 'form',
      }));

  return response.results;
};

const SupportingDataFields = ({ documentType }: { documentType?: UploadDocumentType }) => {
  const { control, watch } = useFormContext<SupportingDataDto>();
  const forProp = getForProp(documentType);
  const { data, isLoading } = useSWR(
    forProp ? (['ticketOrInvoiceOptionsFetcher', forProp] as const) : null,
    ticketOrInvoiceOptionsFetcher,
    {},
  );
  const invoiceKind = watch('invoiceKind');
  const fieldName = forProp === 'invoice' ? 'invoiceId' : 'ticketId';

  const fields = useMemo(() => {
    const label =
      fieldName === 'invoiceId'
        ? `Related to ${invoiceKind ?? 'Invoice or Proposal'} #`
        : 'Related to Ticket #';

    return [
      {
        name: fieldName,
        label,
        type: 'autocomplete',
        options: data?.map((doc) => ({ id: doc.id, label: doc.number })) ?? [],
        loading: isLoading,
        required: false,
        gridProps: {
          xs: 12,
        },
      },
    ] as FieldDef[];
  }, [data, fieldName, invoiceKind, isLoading]);

  return (
    <>
      <Typography variant="h6" my={2}>
        General
      </Typography>
      <Grid container spacing={2} my={2}>
        {fields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
      </Grid>
    </>
  );
};
