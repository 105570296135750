import { format, formatDistanceToNow, formatDuration } from 'date-fns';

type FormatCurrencyType = {
  value: number | null;
  nullValue?: string;
  currencyCode: string;
};

const currencyFormatterUSD = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
});

const currencyFormatterGBP = new Intl.NumberFormat('en-gb', {
  style: 'currency',
  currency: 'GBP',
});

const decimalFormatter = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

const decimalFormatterStrict = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const numberFormatter = new Intl.NumberFormat('en-us', {
  maximumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat('en-us', {
  style: 'percent',
  maximumFractionDigits: 2,
});

export const toDateTimeLocal = (date: string | Date): string => {
  return format(new Date(date), `yyyy-MM-dd'T'HH:mm`);
};

export const toDateLocal = (date: string | Date): string => {
  return format(new Date(date), `yyyy-MM-dd`);
};

export const toDateFormatMMddyyyy = (date: string | Date): string => {
  return format(new Date(date), `MM/dd/yyyy`);
};

export const toDateDistanceToNow = (date: string | Date): string => {
  return `${formatDistanceToNow(new Date(date))} ago)`;
};

export const toLongDateFormat = (date: string | Date): string => {
  return format(new Date(date), `MMM do yyyy`);
};

export const getMonthAndYear = (date: Date) => {
  return format(date, 'yyyy-MM');
};

export const formatCurrency = ({
  value,
  nullValue = '-',
  currencyCode,
}: FormatCurrencyType): string => {
  return value === null
    ? nullValue
    : currencyCode === 'USD'
      ? currencyFormatterUSD.format(value)
      : currencyFormatterGBP.format(value);
};

export const formatDecimal = (value: number | null, nullValue = '-'): string => {
  return value === null ? nullValue : decimalFormatter.format(value);
};

export const formatDecimalStrict = (value: number | null, nullValue = '-'): string => {
  return value === null ? nullValue : decimalFormatterStrict.format(value);
};

export const formatNumber = (value: number | null, nullValue = '-'): string => {
  return value === null ? nullValue : numberFormatter.format(value);
};

export const formatPercent = (value: number | null, nullValue = '-'): string => {
  return value === null ? nullValue : percentFormatter.format(value);
};

export const formatSecondstoHours = (value: number | null, nullValue = '-'): string => {
  return value === null || value === 0
    ? nullValue
    : formatDuration(
        {
          hours: Math.round(value / 3600),
        },
        {
          format: ['hours'],
        },
      );
};

export const formatSecondstoMinutes = (value: number | null, nullValue = '-'): string => {
  return value === null || value === 0
    ? nullValue
    : formatDuration(
        {
          minutes: Math.round(value / 60),
        },
        {
          format: ['minutes'],
        },
      );
};
