import { Dialog, DialogContent, useTheme } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import type { SupportingDataDto } from '~/components/uploadDocument/SupportingDataForm';
import { useSupportingDataForm } from '~/components/uploadDocument/SupportingDataForm';

import type { IDialogBackNextActionButtons } from '../DialogBackNextActions';
import DialogBackNextActions from '../DialogBackNextActions';
import DialogTitleWithCloseButton from '../DialogTitleWithCloseButton';

interface IAddSupportingDataDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: SupportingDataDto) => void;
  defaultValues?: SupportingDataDto;
}

const AddSupportingDataDialog = ({
  open,
  onClose,
  onSubmit,
  defaultValues,
}: IAddSupportingDataDialogProps) => {
  const theme = useTheme();
  const { Fields, Form, hookForm } = useSupportingDataForm();
  const {
    formState: { isSubmitting },
  } = hookForm;

  const actionBtns: IDialogBackNextActionButtons = {
    next: { label: isSubmitting ? 'Saving' : 'Save', enabled: !isSubmitting },
  };

  return (
    <FormProvider {...hookForm}>
      <Form onSubmit={onSubmit} initialData={defaultValues}>
        <Dialog open={open} onClose={onClose} maxWidth="sm" disablePortal>
          <DialogTitleWithCloseButton onClose={onClose} title="Other Supporting Data" />
          <DialogContent
            sx={{ padding: theme.spacing(2), paddingTop: 0, overflow: 'scroll', height: '100%' }}
          >
            <Fields />
          </DialogContent>
          <DialogBackNextActions onBack={onClose} actionBtns={actionBtns} />
        </Dialog>
      </Form>
    </FormProvider>
  );
};

export default AddSupportingDataDialog;
