import z from 'zod';
import { clientSchema } from './client';
import { propertySchema } from './property';
export const portfolioSchema = z.object({
    id: z.string(),
    name: z.string(),
});
export const portfolioClientSchema = portfolioSchema.extend({
    client: clientSchema,
    properties: z.lazy(() => z.array(propertySchema)),
});
export const PortfolioPendingDataSchema = z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    total: z.number(),
    properties: z.array(z.object({
        id: z.string(),
        name: z.string(),
        portfolio: z
            .object({
            id: z.string(),
            name: z.string(),
        })
            .nullable(),
        lastTicket: z.string(),
    })),
});
