import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

interface GenerateOptions {
  /**
   * An optional React Component which will be rendered as the parent of the
   * generated ErrorBoundary. Requires and accepts only a `children` prop: no
   * other props will be passed to it.
   */
  Container?: React.ComponentType<{ children: ReactNode }>;

  errorFormatter?: (routeError: unknown) => {
    title?: string;
    message?: string;
    showReload?: boolean;
  };
}

const defaultMessage =
  'An unrecoverable error occurred. LiftAI has already been informed that this happened and we are certainly looking into it for you. Please try again later.';

/**
 * Generates an ErrorBoundary component that displays an error message and a
 * button to reload the page.
 *
 * @returns {React.FunctionComponent} - The generated ErrorBoundary component.
 */
export function generateErrorBoundary({
  Container = ({ children }) => <>{children}</>,
  errorFormatter = () => ({}),
}: GenerateOptions = {}): React.FunctionComponent {
  const reloadWindow = () => {
    window.location.reload();
  };

  const ErrorBoundary = () => {
    const error = useRouteError();

    const {
      title = 'Uh oh.',
      message = isRouteErrorResponse(error)
        ? `${error.status}\n${error.statusText}`
        : error instanceof Error
          ? error.message + '\n' + error.stack
          : defaultMessage,
      showReload = true,
    } = errorFormatter(error);

    return (
      <Container>
        <Alert
          severity="error"
          variant="outlined"
          sx={{ maxWidth: (theme) => theme.breakpoints.values.md }}
        >
          <AlertTitle>{title}</AlertTitle>
          <Typography whiteSpace="pre-wrap" textAlign="center">
            {message}
          </Typography>
          {showReload ? (
            <Button variant="contained" color="primary" onClick={reloadWindow} sx={{ mt: 2 }}>
              Click here to try again
            </Button>
          ) : null}
          {isRouteErrorResponse(error) && error.status === 403 ? (
            <Button sx={{ display: 'block' }} href="/logout">
              Logout and try again
            </Button>
          ) : null}
        </Alert>
      </Container>
    );
  };

  return ErrorBoundary;
}
