import { z } from 'zod';
import { baseUserGroupSchema } from '../group';
import { userSchema } from '../user';
export const consultantGroupSchema = baseUserGroupSchema.extend({
    consultants: z.array(z.lazy(() => userSchema)),
    /** Schema for User belonging to a ConsultantGroup
     * @owner - User ID of the ConsultantGroup owner
     * @file - Refers to the Consultant Logo [png|jpeg]
     */
    file: z.string().nullable(),
});
