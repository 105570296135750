// This strange line is required for TypeScript support https://mui.com/material-ui/about-the-lab/#typescript
import type {} from '@mui/lab/themeAugmentation';
import type { Theme } from '@mui/material/styles';
import { alpha, createTheme } from '@mui/material/styles';
import type { AllSystemCSSProperties, ResponsiveStyleValue } from '@mui/system';

// This is to define the new variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    secondary: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1b56bb',
      light: '#e7f2ff',
      dark: '#09295f',
    },
    error: {
      main: '#D32F2F',
    },
    warning: {
      main: '#ED6C02',
    },
    success: {
      main: '#2E7D32',
    },
  },
  components: {
    MuiLink: {
      variants: [
        {
          props: { variant: 'secondary' },
          style: {
            textTransform: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '16px',
          },
        },
      ],
    },
  },
});

export const selectedColor: (
  theme: Theme
) => ResponsiveStyleValue<AllSystemCSSProperties['backgroundColor']> = (theme) =>
  alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity);
