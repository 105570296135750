import type { Invoice } from '@liftai/asset-management-types';
import {
  invoiceKindToLabelMap,
  invoiceStatusToColorMap,
  invoiceStatusToLabelMap,
} from '@liftai/asset-management-types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, IconButton, Skeleton, Stack, Typography } from '@mui/material';

import { selectedColor } from '~/theme';
import { toDateFormatMMddyyyy } from '~/utils/format';

interface InvoiceDetailsProps {
  invoice?: Invoice;
  onClose: () => void;
}

export default function InvoiceDetails({ invoice, onClose }: InvoiceDetailsProps) {
  return (
    <Box
      bgcolor={selectedColor}
      p={2}
      pl={3}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Stack flex="1" gap="3px">
        <Box display="flex" flexDirection="row" alignItems="baseline" gap={1}>
          {invoice ? (
            <>
              <Typography variant="body2">{toDateFormatMMddyyyy(invoice.date)}</Typography>

              <Chip
                label={invoiceStatusToLabelMap.get(invoice.status) ?? 'unknown'}
                size="small"
                variant="outlined"
                color={invoiceStatusToColorMap.get(invoice.status) ?? 'primary'}
              />
            </>
          ) : (
            <Skeleton width={125} animation="wave" />
          )}
        </Box>
        <Typography variant="h5">
          {invoice ? (
            `${invoiceKindToLabelMap.get(invoice.kind)} #${invoice.number}`
          ) : (
            <Skeleton width={200} sx={{ fontSize: '1.5rem' }} animation="wave" />
          )}
        </Typography>
      </Stack>
      <Box>
        {/* TODO Implement the menu here. */}
        {/* <IconButton aria-label="more" size="small">
          <MoreVert />
        </IconButton> */}
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
