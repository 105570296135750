interface IDefaultFieldProps extends React.PropsWithChildren {}

export const DefaultFields = () => {
  return <>Form Not Implemented (click next)</>;
};

export const DefaultForm: React.FC<IDefaultFieldProps> = ({ children }) => {
  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </form>
  );
};
