import type { TicketDto } from '@liftai/asset-management-types';
import { Dialog, DialogContent, useTheme } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import DialogBackNextActions from '../DialogBackNextActions';
import DialogTitleWithCloseButton from '../DialogTitleWithCloseButton';
import { useTicketForm } from './TicketForm';

interface IAddTicketDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data?: TicketDto) => void;
  isEdit?: boolean;
  defaultValues?: TicketDto;
}

const AddTicketDialog = ({
  open,
  onClose,
  onSubmit,
  isEdit,
  defaultValues,
}: IAddTicketDialogProps) => {
  const theme = useTheme();
  const { hookForm, Form: TicketForm, Fields: TicketFields } = useTicketForm();
  const {
    formState: { isSubmitting },
  } = hookForm;

  const actionBtns = {
    next: { label: isSubmitting ? 'Saving' : 'Save', enabled: !isSubmitting },
  };

  return (
    <FormProvider {...hookForm}>
      <TicketForm onSubmit={onSubmit} initialData={defaultValues}>
        <Dialog open={open} onClose={onClose} maxWidth="sm" disablePortal>
          <DialogTitleWithCloseButton
            onClose={onClose}
            title={`${isEdit ? 'Edit' : 'Add'} Ticket`}
          />
          <DialogContent
            sx={{ padding: theme.spacing(2), paddingTop: 0, overflow: 'scroll', height: '100%' }}
          >
            <TicketFields />
          </DialogContent>
          <DialogBackNextActions onBack={onClose} actionBtns={actionBtns} />
        </Dialog>
      </TicketForm>
    </FormProvider>
  );
};

export default AddTicketDialog;
