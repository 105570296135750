import { z } from 'zod';
import { baseLinkedAttachmentSchema } from './attachment';
import { metadataSchema } from './metadata';
import { propertyDisplaySchema } from './property';
import { providerSchema } from './provider';
export const contractAttachmentSchema = baseLinkedAttachmentSchema.extend({
    isSummarySheet: z.boolean(),
});
export const ContractType = {
    Full: '24/7',
    Standard: 'standard',
    StandardOt: 'standard-ot-differential',
    PlatinumOt: 'platinum-ot-differential',
    Other: 'other',
};
export const ContractTypeValues = Object.values(ContractType);
export const ContractTypeOptions = {
    [ContractType.Full]: '24/7',
    [ContractType.Standard]: 'Standard',
    [ContractType.StandardOt]: 'Standard OT Differential',
    [ContractType.PlatinumOt]: 'Platinum OT Differential',
    [ContractType.Other]: 'Other',
};
export const contractSchema = z.object({
    id: z.string(),
    name: z.string(),
    attachments: z.array(contractAttachmentSchema),
    serviceProvider: providerSchema,
    property: propertyDisplaySchema.nullable(),
    startDate: z.string(),
    expirationDate: z.string(),
    monthlyPrice: z.coerce.number(),
    term: z.string(),
    type: z.enum(ContractTypeValues),
    contractPaper: z.string(),
    businessHours: z.string(),
    phoneMonitoring: z.boolean().nullable(),
    fireService: z.boolean().nullable(),
    pressureTest: z.boolean().nullable(),
    partsAndMaterialsMarkup: z.coerce.number(),
    cancellation: z.coerce.number(),
    mechanicHourlyRate: z.coerce.number(),
    mechanicHourlyRate15: z.coerce.number(),
    mechanicHourlyRate17: z.coerce.number(),
    mechanicHourlyRate20: z.coerce.number(),
    travelHourlyRate: z.coerce.number(),
    travelHourlyRate15: z.coerce.number(),
    travelHourlyRate17: z.coerce.number(),
    travelHourlyRate20: z.coerce.number(),
    teamHourlyRate: z.coerce.number(),
    teamHourlyRate15: z.coerce.number(),
    teamHourlyRate17: z.coerce.number(),
    teamHourlyRate20: z.coerce.number(),
    maintenanceCompletionTarget: z.coerce.number(),
    maintenanceHoursTarget: z.coerce.number(),
    uptimeTarget: z.coerce.number(),
    onTimeResponseTime: z.coerce.number(),
    emergencyCallRateTarget: z.coerce.number(),
    callbackResponseTime: z.coerce.number(),
    hourlyFeeAdjustmentRate: z.coerce.number(),
    notes: z.string(),
    cars: z.coerce.number(),
    metadata: metadataSchema.array().optional(),
});
export const contractFormValidator = (val, ctx) => {
    const started = val.startDate ? new Date(val.startDate) : null;
    const finished = val.expirationDate ? new Date(val.expirationDate) : null;
    if (started && finished && started > finished) {
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: 'Expiration date must be after Effective since date',
            path: ['expirationDate'],
        });
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: 'Effective since date must be before Expiration date',
            path: ['startDate'],
        });
    }
};
export const contractCustomDetailsSchema = z.array(z.record(z.string()));
export const contractFormBaseSchema = contractSchema
    .omit({
    id: true,
    name: true,
    attachments: true,
    serviceProvider: true,
    property: true,
    mechanicHourlyRate: true,
    mechanicHourlyRate15: true,
    mechanicHourlyRate17: true,
    mechanicHourlyRate20: true,
    travelHourlyRate: true,
    travelHourlyRate15: true,
    travelHourlyRate17: true,
    travelHourlyRate20: true,
    teamHourlyRate: true,
    teamHourlyRate15: true,
    teamHourlyRate17: true,
    teamHourlyRate20: true,
    maintenanceCompletionTarget: true,
    uptimeTarget: true,
    onTimeResponseTime: true,
    emergencyCallRateTarget: true,
    callbackResponseTime: true,
    notes: true,
    cars: true,
    metadata: true,
    phoneMonitoring: true,
    fireService: true,
    pressureTest: true,
})
    .extend({
    phoneMonitoring: z.coerce
        .string()
        .transform((val) => {
        if (val === 'true')
            return true;
        if (val === 'false')
            return false;
        return val;
    })
        .nullable(),
    fireService: z.coerce
        .string()
        .transform((val) => {
        if (val === 'true')
            return true;
        if (val === 'false')
            return false;
        return val;
    })
        .nullable(),
    pressureTest: z.coerce
        .string()
        .transform((val) => {
        if (val === 'true')
            return true;
        if (val === 'false')
            return false;
        return val;
    })
        .nullable(),
    customDetails: z
        .array(z.object({ name: z.string(), value: z.string(), label: z.string() }))
        .optional(),
});
const contractFormPartial = contractFormBaseSchema.partial();
export const contractFormSchema = contractFormBaseSchema.superRefine(contractFormValidator);
export const contractFormSchemaPartial = contractFormPartial.superRefine(contractFormValidator);
