import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';

import DialogTitleWithCloseButton from '~/components/DialogTitleWithCloseButton';

interface AttachmentPreviewDialogProps {
  open: boolean;
  filePath: string;
  onClose: () => void;
  pageNumber?: number;
}

function AttachmentPreviewDialog({
  open,
  filePath,
  onClose,
  pageNumber = 1,
}: AttachmentPreviewDialogProps) {
  const [dialogWidth, setDialogWidth] = useState<string>('100%');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      sx={{
        // Needed to make the dialog full height so that the DocumentPreview dimensions can be correctly calculated
        '& > .MuiDialog-container > .MuiPaper-root': {
          height: '100%',
          width: dialogWidth,
        },
      }}
    >
      <DialogTitleWithCloseButton onClose={onClose} title="Preview Document" />

      <Document
        file={filePath}
        onLoadSuccess={async (page) => {
          const pageObj = await page.getPage(pageNumber);
          const pageWidth = pageObj.view[2];

          if (pageWidth) {
            // 20 is the width of the scrollbar
            setDialogWidth(`${pageWidth + 20}px`);
          }
        }}
      >
        <Page pageNumber={pageNumber} scale={1} renderAnnotationLayer={false} />
      </Document>
    </Dialog>
  );
}

export default AttachmentPreviewDialog;
