import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { alpha, IconButton, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

const minScale = 1;
const scaleUpRatio = 1.1;
const scaleDownRatio = 1 / scaleUpRatio;
const formatScale = (scale: number) => `${(scale * 100).toFixed(0)}%`;

interface IZoomControlProps {
  onSetZoomLevel: (scale: number) => void;
  scale: number;
}

const ZoomControls: React.FC<IZoomControlProps> = ({ scale, onSetZoomLevel }) => {
  const theme = useTheme();
  const [pendingScale, setPendingScale] = useState(formatScale(scale));

  const onSetZoomLevelWrapper = useCallback(
    (scale: number) => {
      onSetZoomLevel(scale >= minScale ? scale : minScale);
    },
    [onSetZoomLevel],
  );

  const onCustomZoomChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPendingScale(event.target.value);
  }, []);

  const onInputBlur = useCallback(() => {
    const scale = parseFloat(pendingScale.replace(/%/g, '')) / 100;
    if (!Number.isNaN(scale)) {
      onSetZoomLevelWrapper(scale);
    }
  }, [pendingScale, onSetZoomLevelWrapper]);

  useEffect(() => {
    setPendingScale(formatScale(scale));
  }, [scale]);

  return (
    <div
      style={{
        position: 'sticky',
        left: '0',
        right: '0',
        textAlign: 'center',
        bottom: theme.spacing(1),
        zIndex: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          // TODO: Auto adjust for dark mode
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: theme.spacing(0, 1),
          borderRadius: theme.shape.borderRadius,
          background: alpha(theme.palette.common.black, 0.7),
          color: theme.palette.common.white,
        }}
      >
        <IconButton
          type="button"
          color="inherit"
          disabled={scale === minScale}
          onClick={() => onSetZoomLevelWrapper(scale * scaleDownRatio)}
        >
          <Remove />
        </IconButton>
        <input
          type="text"
          style={{
            margin: theme.spacing(2, 'auto'),
            border: 0,
            padding: theme.spacing(1),
            width: '72px',
            textAlign: 'center',
            background: alpha(theme.palette.common.black, 0.12),
            color: 'inherit',
          }}
          value={pendingScale}
          onChange={onCustomZoomChange}
          onBlur={onInputBlur}
        />
        <IconButton
          type="button"
          color="inherit"
          onClick={() => onSetZoomLevelWrapper(scale * scaleUpRatio)}
        >
          <Add />
        </IconButton>
      </div>
    </div>
  );
};

export default ZoomControls;
