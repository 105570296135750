import { styled } from '@mui/material/styles';
import { PickersDay, type PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { endOfMonth, isSameDay, startOfMonth } from 'date-fns';
import React from 'react';

import { isInRange, isInSameMonth } from './utils';

interface CustomPickerDayProps extends PickersDayProps<Date> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    color: theme.palette.primary.dark,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
      color: theme.palette.primary.dark,
    },
  }),
  ...(isSameDay(day, startOfMonth(day)) && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isSameDay(day, endOfMonth(day)) && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>;

function Day(
  props: PickersDayProps<Date> & {
    selectedDay?: [Date | null, Date | null];
    hoveredDay?: Date | null;
    isVisuallySelected?: boolean;
    isPreviewing?: boolean;
    isEndOfHighlighting?: boolean;
    isStartOfPreviewing?: boolean;
    isStartOfHighlighting?: boolean;
    isEndOfPreviewing?: boolean;
    isHighlighting?: boolean;
  }
) {
  // All the is... properties cause error if we don't remove from the "other"
  const {
    day,
    selectedDay,
    hoveredDay,
    isVisuallySelected,
    isPreviewing,
    isEndOfHighlighting,
    isStartOfPreviewing,
    isStartOfHighlighting,
    isEndOfPreviewing,
    isHighlighting,
    ...other
  } = props;

  const isSelected = (day: Date) => {
    return isInRange(day, selectedDay);
  };

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isSelected(day)}
      isHovered={isInSameMonth(day, hoveredDay)}
    />
  );
}

export default Day;
