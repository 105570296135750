import z from 'zod';
import { invoiceSchema } from '../invoice';
import { propertyDisplaySchema } from '../property';
export const invoiceTableSchema = invoiceSchema
    .pick({
    id: true,
    number: true,
    kind: true,
    type: true,
    date: true,
    dateStamped: true,
    status: true,
    region: true,
    reason: true,
    countTowardsSavings: true,
})
    .extend({
    property: propertyDisplaySchema,
    serviceProvider: z.object({ id: z.string(), name: z.string() }).strict(),
    carsServiced: z.object({ id: z.string(), name: z.string() }).strict().array(),
    consultant: z.object({ id: z.string(), fullName: z.string() }).strict(),
    proposedAmount: z.string(),
    reviewedAmount: z.string(),
})
    .strict()
    .array();
export const invoiceFormSchema = invoiceSchema
    .pick({
    id: true,
    number: true,
    property: true,
})
    .strict()
    .array();
