import { type Invoice, InvoiceKind } from '@liftai/asset-management-types';
import { InvoiceActionType, invoiceKindToLabelMap } from '@liftai/asset-management-types';
import Download from '@mui/icons-material/Download';
import { Button, Dialog, DialogContent, Grid, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';

interface ConfirmationDialogProps {
  actionType: InvoiceActionType;
  invoice: Invoice;
  onClose: () => void;
  onDownloadPDF: (includeSupportingData?: boolean) => Promise<void>;
}
const getActionVerb = (actionType: InvoiceActionType) => {
  switch (actionType) {
    case InvoiceActionType.PARTIAL:
      return 'Partially Approved';
    case InvoiceActionType.REJECT:
      return 'Rejected';
    case InvoiceActionType.APPROVE:
      return 'Approved';
    default:
      throw new Error('Unknown action type');
  }
};

const getActionColor = (actionType: InvoiceActionType) => {
  switch (actionType) {
    case InvoiceActionType.PARTIAL:
      return 'warning.main';
    case InvoiceActionType.REJECT:
      return 'error.main';
    case InvoiceActionType.APPROVE:
      return 'success.main';
    default:
      throw new Error('Unknown action type');
  }
};

// confirmation dialog for each of the invoice actions
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  actionType,
  invoice,
  onClose,
  onDownloadPDF,
}) => {
  const invoiceOrProposal = invoiceKindToLabelMap.get(invoice.kind)!;
  const savedAmount = invoice.savings ? invoice.savings.toLocaleString() : null;
  // TODO: The type cast is because savings is actually a string as returned from the API.
  const hasSaving = invoice.savings && parseFloat(invoice.savings as unknown as string) > 0;
  const title = hasSaving
    ? `Congratulations, you saved your client $${savedAmount}!`
    : 'One less to go!';
  const content = `${invoiceOrProposal} #${invoice.number} has been `;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          spacing={1}
        >
          <Grid item>
            <img src="images/high-five.svg" alt="Congratulations" />
          </Grid>
          <Grid item>
            <Typography variant="h4" color="text.primary">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="span" color="text.primary">
              {content}
            </Typography>
            &nbsp;
            <Typography
              variant="h5"
              component="span"
              color={getActionColor(actionType)}
              sx={{ fontWeight: 'bold' }}
            >
              {getActionVerb(actionType)}
            </Typography>
          </Grid>
          <Grid item mt={3} mb={2}>
            <Button onClick={handleClick} sx={{ pl: 0 }}>
              <Download sx={{ fontSize: 16, mr: 1 }} /> Download
            </Button>
            <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
              <MenuItem
                onClick={async () => {
                  await onDownloadPDF(false);
                  setAnchorEl(null);
                }}
              >
                {invoice.kind === InvoiceKind.Proposal
                  ? 'Download Stamped Proposal'
                  : 'Download Stamped Invoice'}
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  await onDownloadPDF(true);
                  setAnchorEl(null);
                }}
              >
                Download with Supporting Data
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
