import type { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import type { DateRange } from '@mui/x-date-pickers-pro';
import {
  eachQuarterOfInterval,
  endOfQuarter,
  endOfYear,
  getYear,
  startOfYear,
  subYears,
} from 'date-fns';

const getQuartersItems = () => {
  const today = new Date();
  const startDate = startOfYear(subYears(today, 1));
  const endDate = endOfYear(today);

  const quarters = eachQuarterOfInterval({
    start: startDate,
    end: endDate,
  });

  let quarterNumber = 0;
  const shortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = quarters.map((quarter) => {
    quarterNumber++;
    if (quarterNumber > 4) quarterNumber = 1;
    return {
      label: `Q${quarterNumber} ${getYear(quarter)}`,
      getValue: () => {
        return [quarter, endOfQuarter(quarter)];
      },
    };
  });

  return shortcutsItems.reverse();
};

export default getQuartersItems;
