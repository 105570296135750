import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import type { Accept, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import useLAISnackbar from '~/hooks/useLAISnackbar';

export interface IDragDropZoneProps {
  acceptedFileTypesLabel: string;
  fileSizeLimit: number; // in MB
  acceptedFileTypes: Accept;
  onFileSelected: (files: File[]) => void;
  loading?: boolean;
}

const DragDropZone: React.FC<IDragDropZoneProps> = ({
  onFileSelected,
  fileSizeLimit,
  acceptedFileTypes,
  acceptedFileTypesLabel,
  loading: progress,
}) => {
  const { showSnackbar } = useLAISnackbar();

  const errorMessages: { [key: string]: string } = {
    'file-too-large': `File is too large (max ${fileSizeLimit}MB). Please try again.`,
    'file-invalid-type': 'File is not a PDF. Please upload a PDF.',
    'too-many-files': 'No files were uploaded. We only allow one file to be uploaded at a time',
  };

  const getErrorMessage = (rejection: FileRejection) => {
    return rejection.errors
      .map((error) => errorMessages[error.code] || `Error: ${error.message}`)
      .join('. ');
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: fileSizeLimit * 1024 * 1024,
    multiple: false,
    disabled: progress,

    onDrop: async (acceptedFiles: File[]) => {
      onFileSelected(acceptedFiles);
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((fileRejection) => {
        showSnackbar(getErrorMessage(fileRejection), {
          variant: 'error',
          persist: true,
        });
      });
    },
  });

  const theme = useTheme();

  const style = React.useMemo(() => {
    const activeStyle = {
      borderColor: theme.palette.info.main,
    };

    const acceptStyle = {
      borderColor: theme.palette.success.main,
    };

    const rejectStyle = {
      borderColor: theme.palette.error.main,
    };

    const disabledStyle = {
      backgroundColor: alpha(theme.palette.common.black, 0.12),
    };

    return {
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(progress ? disabledStyle : {}),
    };
  }, [isDragAccept, isDragActive, isDragReject, progress, theme]);

  return (
    <Box
      bgcolor={theme.palette.common.white}
      padding={2}
      textAlign="center"
      border={`1px dashed ${alpha(theme.palette.common.black, 0.12)};`}
      sx={{
        position: 'relative',
        '&:hover': {
          backgroundColor: alpha(theme.palette.info.main, 0.12),
          cursor: 'pointer',
        },
      }}
      borderRadius={1}
      {...getRootProps({ style })}
    >
      {progress ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : null}

      <input {...getInputProps()} />

      <IconButton
        sx={{
          backgroundColor: alpha(theme.palette.primary.main, 0.12),
        }}
        color="primary"
      >
        <UploadFileOutlinedIcon />
      </IconButton>
      <Typography variant="body2">
        <Button>Click to upload</Button>
        {'or drag and drop'}
      </Typography>
      <Typography variant="body2">{`${acceptedFileTypesLabel} (max. ${fileSizeLimit}MB)`}</Typography>
    </Box>
  );
};

export default DragDropZone;
