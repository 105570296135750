import type { Invoice, RelatedInvoice, Ticket } from '@liftai/asset-management-types';
import {
  InvoiceKind,
  InvoiceStatus,
  invoiceStatusToColorMap,
  invoiceStatusToLabelMap,
  invoiceTypeToLabelMap,
  isInvoiceFinal,
} from '@liftai/asset-management-types';
import type { LineItem } from '@liftai/asset-management-types/src/lineItem';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from '@mui/x-data-grid-premium';
import React, { useContext, useState } from 'react';

import { UserContext } from '~/auth/userContext';
import Skeleton, { SkeletonTable } from '~/components/common/Skeleton';
import { selectedTabSearchParam } from '~/components/global/constants';
import { DownloadStampedInvoiceDropdownMenu } from '~/components/invoices/stampedInvoice';
import { yesOrNo } from '~/components/utils/booleanToText';
import { LiftAITextLinkNavigator } from '~/components/utils/helpers';
import useInvoice from '~/data/hooks/useInvoice';
import useInvoices from '~/data/hooks/useInvoices';
import { formatCurrency, toLongDateFormat } from '~/utils/format';
import { buildUrlForInvoices, buildUrlForTickets } from '~/utils/urlBuilder';

interface IInvoiceOverviewProps {
  invoice?: Invoice;
  tickets: Ticket[] | undefined;
}

type IInvoiceOverviewField = {
  /** The label of the field. */
  label: string;
  /** The name of the field. */
  name: keyof Invoice | 'stampedInvoice';
  /** The value of the field, if any. */
  value?: string;
  /** The caption of the field, if any. */
  caption?: string;
  /** The default value of the field, if `value` is falsy. */
  defaultValue?: string;
  /** Whether the field should take up the full width of its container. */
  fullWidth?: boolean;
} & (
  | { type: 'link'; uri: string; element?: never }
  | { type: 'text'; uri?: never; element?: never }
  | { type: 'custom'; uri?: never; element: () => JSX.Element }
);

const afterReviewField = (reviewedAmount: number, savings: number | null, currencyCode: string) =>
  ({
    label: 'After Review',
    name: 'reviewedAmount',
    value:
      formatCurrency({ value: reviewedAmount, currencyCode }) +
      (savings ? ` (${formatCurrency({ value: savings, currencyCode })} Savings)` : ''),
    type: 'text',
  }) as IInvoiceOverviewField;

export const getDecisionFields = (
  invoice: Invoice,
  currencyCode: string,
): IInvoiceOverviewField[] => {
  const fields: IInvoiceOverviewField[] = [
    {
      label: 'Date Stamped',
      name: 'dateStamped',
      value: invoice.dateStamped ? toLongDateFormat(invoice.dateStamped) : 'N/A',
      type: 'text',
    },
    {
      label: 'Stamped By',
      name: 'consultant',
      value: invoice.consultant?.fullName ?? 'N/A',
      type: 'text',
    },
  ];

  const reasonField: IInvoiceOverviewField = {
    label: 'Reason',
    name: 'reason',
    value: invoice.reason ?? 'N/A',
    type: 'text',
    fullWidth: true,
  };

  const downloadField: IInvoiceOverviewField = {
    label: invoice.kind === InvoiceKind.Proposal ? 'Stamped Proposal' : 'Stamped Invoice',
    name: 'stampedInvoice',
    value: 'DOWNLOAD',
    type: 'custom',
    element: () => <DownloadStampedInvoiceDropdownMenu invoice={invoice} />,
  };

  const savings: number | null = isInvoiceFinal(invoice)
    ? // TODO The types say that `proposedAmount` and `reviewedAmount` is a number, but it's actually a string.
      // Possibly because it's not going through Zod which coerces the string to a number
      invoice.savings ?? 0
    : null;

  switch (invoice.status) {
    case InvoiceStatus.Approved:
      return [...fields, downloadField];

    case InvoiceStatus.Rejected:
      return [
        ...fields,
        invoice.countTowardsSavings ? afterReviewField(0, savings, currencyCode) : null,
        downloadField,
        {
          label: 'Counts Towards Savings',
          name: 'countTowardsSavings',
          value: yesOrNo(invoice.countTowardsSavings),
          type: 'text',
        },
        reasonField,
      ].filter((field): field is IInvoiceOverviewField => field !== null);

    case InvoiceStatus.PartialApproval:
      return [
        ...fields,
        afterReviewField(Number(invoice.reviewedAmount), savings, currencyCode),
        downloadField,
        reasonField,
      ];

    default:
      console.warn(`Unknown invoice status: ${invoice.status}`);
      return [];
  }
};

const getFields = (invoice?: Invoice): IInvoiceOverviewField[] => [
  {
    label: 'Property',
    name: 'property',
    value: invoice?.property?.name,
    caption: invoice?.property?.address,
    uri: `/portfolio/${invoice?.property?.id}?selectedInvoiceId=${invoice?.id}&${selectedTabSearchParam}=CONTRACTS`,
    type: 'link',
  },

  {
    label: 'Service Provider',
    value: invoice?.serviceProvider?.name,
    name: 'serviceProvider',
    type: 'text',
  },
  {
    label: 'Type',
    value: invoice && invoiceTypeToLabelMap.get(invoice.type),
    name: 'type',
    type: 'text',
  },
  {
    label: 'Consultant',
    value: invoice?.consultant?.fullName,
    defaultValue: 'N/A',
    name: 'consultant',
    type: 'text',
  },
  {
    label: 'Description',
    value: invoice?.description,
    defaultValue: 'No description.',
    name: 'description',
    type: 'text',
    fullWidth: true,
  },
];

const renderLinkOrTextField = (field: IInvoiceOverviewField) => {
  if (field.value === undefined && field.defaultValue === undefined)
    return <Skeleton width={'75%'} />;

  switch (field.type) {
    case 'text':
      return <Typography variant="body2">{field.value || field.defaultValue}</Typography>;
    case 'link':
      return (
        <Typography variant="body2">
          <LiftAITextLinkNavigator
            label={(field.value || field.defaultValue) ?? ''}
            href={field.uri}
          />
        </Typography>
      );
    case 'custom':
      return <field.element />;
    default:
      return null;
  }
};

const renderFieldAndGrid = (field: IInvoiceOverviewField, index: number) => {
  return (
    <Grid item xs={field.fullWidth ? 12 : 6} key={index} my={2}>
      <Typography variant="body2" color="text.secondary">
        {field.label}
      </Typography>
      {renderLinkOrTextField(field)}
      {field.caption ? (
        <Typography variant="caption" color="text.secondary">
          {field.caption}
        </Typography>
      ) : null}
    </Grid>
  );
};

const LineItems = ({ lineItems, total }: { lineItems: LineItem[]; total?: number }) => {
  const [sortedLineItems, setSortedLineItems] = useState<LineItem[]>(lineItems);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const handleSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (sortDirection === 'asc') {
      setSortedLineItems([...lineItems].sort((a, b) => b.amount - a.amount));
      setSortDirection('desc');
    } else {
      setSortedLineItems([...lineItems].sort((a, b) => a.amount - b.amount));
      setSortDirection('asc');
    }
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          {/* amount with sort icon arrow up for sorting ascending and arrow down for sorting descending */}
          <TableCell>
            Amount
            {sortDirection === 'asc' ? (
              <IconButton onClick={handleSort}>
                <GridArrowUpwardIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleSort}>
                <GridArrowDownwardIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedLineItems.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.description}</TableCell>
            <TableCell>{item.amount}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>{total}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const Tickets = ({ tickets }: { tickets: Ticket[] | undefined }) => {
  if (!tickets) {
    return (
      <List data-testid="ticket-loading-skeleton">
        {new Array(3).fill(0).map((_, index) => (
          <ListItem key={index}>
            <Stack sx={{ width: '100%' }}>
              <Skeleton
                animation="wave"
                width="35%"
                sx={{ bgcolor: 'primary.main', opacity: 0.25 }}
              />
              <Skeleton animation="wave" width="70%" />
            </Stack>
          </ListItem>
        ))}
      </List>
    );
  }

  const searchParams = new URLSearchParams(window.location.search);
  const startDate = searchParams.get('start_date') ?? ''; // It should not happen that the search params are empty
  const endDate = searchParams.get('end_date') ?? ''; // It should not happen that the search params are empty
  const selectedInvoiceId = searchParams.get('selectedInvoiceId') ?? ''; // It should not happen that the search params are empty

  return (
    <List>
      {tickets.map((ticket, index) => {
        const url = buildUrlForTickets({
          startDate,
          endDate,
          number: ticket.number,
          property: ticket.property.id,
          selectedInvoiceId,
          ticketId: ticket.id,
        });

        return (
          <ListItem key={index}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <LiftAITextLinkNavigator label={ticket.number} href={url} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  Time on site: {ticket.timeOnSiteRegularHours}. Travel Time:{' '}
                  {ticket.travelTimeRegularHours}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  Time on site (OT): {ticket.timeOnSiteOvertime ?? '-'} | Travel Time (OT):{' '}
                  {ticket.travelTimeOvertime ?? '-'}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
      {tickets.length === 0 && (
        <ListItem>
          <Typography variant="body2">No tickets</Typography>
        </ListItem>
      )}
    </List>
  );
};

const RelatedInvoices = ({
  relatedInvoices,
  onAddRelatedInvoice,
  onRemoveRelatedInvoice,
  invoiceOptions,
}: {
  relatedInvoices: RelatedInvoice[] | undefined;
  onAddRelatedInvoice: (invoiceId: string) => Promise<void>;
  onRemoveRelatedInvoice: (invoiceId: string) => void;
  invoiceOptions?: { id: string; label: string }[];
}) => {
  const [value, setValue] = useState<{ id: string; label: string } | null>(null);
  const [isLinking, setIsLinking] = useState<boolean>(false);

  const { currencyCode } = useContext(UserContext);

  if (!relatedInvoices) {
    return (
      <List data-testid="related-invoices-loading-skeleton">
        {new Array(3).fill(0).map((_, index) => (
          <ListItem key={index}>
            <Stack sx={{ width: '100%' }}>
              <Skeleton
                animation="wave"
                width="35%"
                sx={{ bgcolor: 'primary.main', opacity: 0.25 }}
              />
              <Skeleton animation="wave" width="70%" />
            </Stack>
          </ListItem>
        ))}
      </List>
    );
  }

  const searchParams = new URLSearchParams(window.location.search);
  const startDate = searchParams.get('start_date') ?? ''; // It should not happen that the search params are empty
  const endDate = searchParams.get('end_date') ?? ''; // It should not happen that the search params are empty

  return (
    <List>
      {relatedInvoices.map((invoice, index) => {
        const url = buildUrlForInvoices({
          startDate,
          endDate,
          invoiceId: invoice.id,
          kind: invoice.kind,
        });

        return (
          <ListItem key={index}>
            <Grid
              container
              flexDirection="row"
              justifyItems="flex-start"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Grid item container xs={10}>
                <Grid item xs={12}>
                  <Typography variant="body2" component="span">
                    <LiftAITextLinkNavigator label={invoice.number} href={url} />
                  </Typography>
                  <Chip
                    label={invoiceStatusToLabelMap.get(invoice.status) ?? 'unknown'}
                    size="small"
                    variant="outlined"
                    sx={{
                      marginLeft: 1.25,
                    }}
                    color={invoiceStatusToColorMap.get(invoice.status) ?? 'primary'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    Amount:{' '}
                    {formatCurrency({
                      value: parseFloat(invoice.proposedAmount) ?? 0,
                      currencyCode,
                    })}{' '}
                    {invoice.reviewedAmount
                      ? `| Amount after review: ${formatCurrency({ value: parseFloat(invoice.reviewedAmount) ?? 0, currencyCode })}`
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => onRemoveRelatedInvoice(invoice.id)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
      {relatedInvoices.length === 0 && (
        <ListItem>
          <Typography variant="body2">No related invoices/proposals</Typography>
        </ListItem>
      )}
      <ListItem>
        <Grid
          container
          flexDirection="row"
          justifyItems="flex-start"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ backgroundColor: '#E7F2FF', paddingBottom: 1, paddingX: 2, borderRadius: 1 }}
        >
          <Grid item sx={{ paddingTop: 1 }}>
            <Autocomplete
              value={value ?? null}
              onChange={(event, option) => {
                setValue(option);
              }}
              key={value?.id}
              noOptionsText="No options"
              sx={{ width: '330px', backgroundColor: '#fff', borderRadius: 1 }}
              loading={invoiceOptions === undefined}
              renderInput={(props) => (
                <TextField {...props} label="Invoice/proposal Number" name="invoiceId" />
              )}
              fullWidth
              options={invoiceOptions ?? []}
              getOptionLabel={(option) =>
                invoiceOptions?.find((invoice) => invoice.id === option.id)?.label ?? ''
              }
              size="small"
            />
          </Grid>
          <Grid item sx={{ paddingTop: 1 }}>
            <LoadingButton
              type="button"
              onClick={async () => {
                try {
                  if (value) {
                    setIsLinking(true);
                    await onAddRelatedInvoice(value.id);
                  }
                } finally {
                  setValue(null);
                  setIsLinking(false);
                }
              }}
              loading={isLinking}
              color="primary"
              variant="outlined"
              size="medium"
              startIcon={<AddIcon />}
              disabled={!value}
            >
              Link
            </LoadingButton>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
};

const CarsServiced = ({ invoice }: { invoice: Invoice }) => {
  return (
    <Table>
      <TableBody>
        {invoice.carsServiced.map((car, index) => (
          <TableRow key={index}>
            <TableCell>
              <Typography variant="body2">{car.name}</Typography>
            </TableCell>
          </TableRow>
        ))}
        {invoice.carsServiced.length === 0 && (
          <TableRow>
            <TableCell>
              <Typography variant="body2">No cars serviced</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const InvoiceOverview: React.FC<IInvoiceOverviewProps> = ({ invoice, tickets }) => {
  const fields = getFields(invoice);
  const invoiceIsFinal = invoice ? isInvoiceFinal(invoice) : false;
  const { currencyCode } = useContext(UserContext);

  // TODO: We should probably filter by the search input
  const { invoices: invoiceList } = useInvoices(
    {
      property: invoice?.property?.id,
      variant: 'form',
    },
    invoice?.property?.id !== undefined,
  );

  const invoiceOptions = invoiceList
    ?.filter(
      (option) =>
        !invoice?.relatedInvoices?.some((invoice) => invoice.id === option.id) &&
        option.id !== invoice?.id,
    )
    .map((invoice) => ({
      id: invoice.id,
      label: invoice.number,
    }));

  const { updateRelatedInvoice } = useInvoice(invoice?.id ?? null);

  const onAddRelatedInvoice = async (invoiceId: string) => {
    await updateRelatedInvoice([
      ...(invoice?.relatedInvoices ?? []).map((invoice) => invoice.id),
      invoiceId,
    ]);
  };

  const onRemoveRelatedInvoice = async (invoiceId: string) => {
    await updateRelatedInvoice(
      (invoice?.relatedInvoices ?? [])
        .filter((invoice) => invoice.id !== invoiceId)
        .map((invoice) => invoice.id),
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Grid
        container
        flexDirection="column"
        sx={{ height: '100%', position: 'absolute', overflowY: 'auto' }}
      >
        <Grid container px={2} py={3}>
          {fields.map((field, index) => renderFieldAndGrid(field, index))}

          {invoiceIsFinal ? (
            <Grid item xs={12} my={2}>
              <Typography variant="h6">Decision</Typography>
              <Grid container>
                {getDecisionFields(invoice!, currencyCode).map((field, index) =>
                  renderFieldAndGrid(field, index),
                )}
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12} my={2}>
            <Typography variant="h6">Line Items</Typography>
            {invoice ? (
              <LineItems lineItems={invoice.lineItems} total={invoice.proposedAmount} />
            ) : (
              <SkeletonTable columns={['Description', 'Amount']} rows={5} />
            )}
          </Grid>
          <Grid item xs={12} my={2}>
            <Typography variant="h6">Tickets</Typography>
            <Tickets tickets={tickets} />
          </Grid>
          <Grid item xs={12} my={2}>
            <Typography variant="h6">Linked Invoices & Proposals</Typography>
            <RelatedInvoices
              invoiceOptions={invoiceOptions}
              relatedInvoices={invoice?.relatedInvoices}
              onAddRelatedInvoice={onAddRelatedInvoice}
              onRemoveRelatedInvoice={onRemoveRelatedInvoice}
            />
          </Grid>
          <Grid item xs={12} my={2}>
            <Typography variant="h6">Cars Serviced</Typography>
            {invoice ? (
              <CarsServiced invoice={invoice} />
            ) : (
              <SkeletonTable columns={['-']} rows={3} hideHeader />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceOverview;
