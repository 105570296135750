import type { UserRole } from '@liftai/asset-management-types';
import Close from '@mui/icons-material/Close';
import fuzzysort from 'fuzzysort';
import { useCallback, useEffect, useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { selectedInvoiceIdKey } from '~/components/global/constants';
import { CarIcon, ContractIcon, InvoiceIcon, PropertyIcon, TicketIcon } from '~/components/icons';
import { useLAINavigate } from '~/hooks/useLAINavigate';
import { getApiClient } from '~/utils/api';

const MAX_RESULTS = 10;

const onSearch = async (query: string) => {
  if (!query) {
    return {
      cars: [],
      contracts: [],
      properties: [],
      invoices: [],
      tickets: [],
    };
  }

  const apiClient = getApiClient();
  const { cars, invoices, tickets, contracts, properties } = await apiClient.search(query);

  return {
    cars: cars.map((car) => ({
      label: `${car.property?.name} - ${car.name}`,
      link: `/portfolio/${car.property.id}?carId=${car.id}`,
    })),
    contracts: fuzzysort
      .go(query, contracts, {
        key: 'name',
        limit: MAX_RESULTS,
      })
      .map(({ obj }) => ({
        label: `${obj.property.name} - ${obj.name}`,
        link: `/portfolio/${obj.property?.id}?contractId=${obj.id}`,
      })),
    properties: fuzzysort
      .go(query, properties, {
        key: 'name',
        limit: MAX_RESULTS,
      })
      .map(({ obj }) => ({
        label: obj.name,
        link: `/portfolio/${obj.id}`,
      })),
    invoices: fuzzysort
      .go(query, invoices, {
        key: 'number',
        limit: MAX_RESULTS,
      })
      .map(({ obj }) => ({
        label: `${obj.property?.name} - ${obj.number}`,
        link: `?${selectedInvoiceIdKey}=${obj.id}`,
      })),
    tickets: fuzzysort
      .go(query, tickets, {
        key: 'number',
        limit: MAX_RESULTS,
      })
      .map(({ obj }) => ({
        label: `${obj.property?.name} - ${obj.number}`,
        link: `/tickets?ticketId=${obj.id}`,
      })),
  };
};

export const useLAICommandBar = ({ uid, role }: { uid: string; role: UserRole }) => {
  const { navigate } = useLAINavigate();

  useEffect(() => {
    // Only consultants have access to the command bar currently
    if (role !== 'consultant') {
      return;
    }

    const CommandBar = window.CommandBar;

    if (!CommandBar) {
      console.error('CommandBar not found');
      return;
    }

    void CommandBar.boot(uid).then(() => {
      CommandBar.addRouter((path: string) => {
        const url = new URL(
          // This is a special case to handle relative paths
          path.startsWith('?') ? window.location.pathname + path : path,
          window.location.origin,
        );

        navigate({
          pathname: url.pathname,
          search: url.search,
        });
      });

      CommandBar.addMultiSearch(onSearch, [
        'properties',
        'cars',
        'tickets',
        'contracts',
        'invoices',
      ]);

      // Properties
      CommandBar.addRecords('properties', [], {
        defaultIcon: renderToStaticMarkup(<PropertyIcon />),
        searchTabEnabled: true,
      });

      void CommandBar.addRecordAction('properties', {
        text: 'Open Property Detail',
        name: 'open_property_detail',
        template: {
          type: 'link',
          value: '{{record.link}}',
          operation: 'router',
        },
      });

      // Cars
      CommandBar.addRecords('cars', [], {
        defaultIcon: renderToStaticMarkup(<CarIcon />),
        searchTabEnabled: true,
      });

      void CommandBar.addRecordAction('cars', {
        text: 'Open Car Detail',
        name: 'open_car_detail',
        template: {
          type: 'link',
          value: '{{record.link}}',
          operation: 'router',
        },
      });

      // Tickets
      CommandBar.addRecords('tickets', [], {
        defaultIcon: renderToStaticMarkup(<TicketIcon />),
        searchTabEnabled: true,
      });

      void CommandBar.addRecordAction('tickets', {
        text: 'Open Ticket Detail',
        name: 'open_ticket_detail',
        template: {
          type: 'link',
          value: '{{record.link}}',
          operation: 'router',
        },
      });

      // Contracts
      CommandBar.addRecords('contracts', [], {
        defaultIcon: renderToStaticMarkup(<ContractIcon />),
        searchTabEnabled: true,
      });

      void CommandBar.addRecordAction('contracts', {
        text: 'Open Contract Detail',
        name: 'open_contract_detail',
        template: {
          type: 'link',
          value: '{{record.link}}',
          operation: 'router',
        },
      });

      // Invoices
      CommandBar.addRecords('invoices', [], {
        defaultIcon: renderToStaticMarkup(<InvoiceIcon />),
        searchTabEnabled: true,
      });

      void CommandBar.addRecordAction('invoices', {
        text: 'Open Invoice Detail',
        name: 'open_invoice_detail',
        template: {
          type: 'link',
          value: `{{record.link}}`,
          operation: 'router',
        },
      });

      // Close Invoice Detail
      void CommandBar.addCommand({
        text: 'Close Invoice Detail',
        name: 'close_invoice_detail',
        template: {
          type: 'callback',
          value: 'close_invoice_detail',
        },
        category: 'Invoices',
        availability_rules: [{ type: 'url', value: selectedInvoiceIdKey, operator: 'includes' }],
        icon: renderToStaticMarkup(<Close />),
      });
    });

    CommandBar.addCallback('close_invoice_detail', () => {
      navigate(
        {
          pathname: window.location.pathname,
        },
        { paramsToKeep: [] },
      );
    });

    return () => {
      CommandBar.shutdown();
    };
  }, [uid, navigate, role]);

  const openCommandBar = useCallback(() => {
    window.CommandBar.open();
  }, []);

  return useMemo(() => ({ openCommandBar }), [openCommandBar]);
};
