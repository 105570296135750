import SearchIcon from '@mui/icons-material/Search';
import {
  AppBar as MUIAppBar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  styled,
  Toolbar,
} from '@mui/material';
import { useContext, useEffect, useState, useSyncExternalStore } from 'react';
import { useNavigation } from 'react-router-dom';

import { UserContext } from '~/auth/userContext';
import { InvoiceIcon } from '~/components/icons';
import { InvoiceDetailDrawerFlowContext } from '~/components/invoices/InvoiceDetailDrawerContext';
import UploadDocumentButton from '~/components/uploadDocument/UploadDocumentButton';
import { useGlobalFilters } from '~/hooks/useGlobalFilters';
import {
  canAccess,
  PENDING_INVOICES_NOTIFICATION,
  UNIVERSAL_SEARCH,
  UPLOAD_DOCUMENT_FORM,
} from '~/utils/role';

import AppBarButton from './AppBarButton';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const DEFAULT_APP_BAR_HEIGHT = 64;

const useIsFetching = () => {
  const { state } = useNavigation();
  // TODO Also check SWR for any of its in-progress loaders.
  return state === 'loading';
};

const appBarObserver = {
  value: 0,
  observe: (callback: () => void) => {
    const getAppBarHeight: ResizeObserverCallback = ([{ target: appBar }]) => {
      if (appBar) {
        appBarObserver.value = appBar.clientHeight;
        callback();
      }
    };

    const resizeObserver = new ResizeObserver(getAppBarHeight);

    resizeObserver.observe(document.querySelector('header.MuiAppBar-root')!);

    return () => resizeObserver.disconnect();
  },
  getSnapshot: () => appBarObserver.value,
};

export const useAppBarHeight = (): number => {
  return useSyncExternalStore(
    appBarObserver.observe,
    appBarObserver.getSnapshot,
    () => DEFAULT_APP_BAR_HEIGHT,
  );
};

interface AppBarProps {
  numPendingInvoices?: number;
  openSearch: () => void;
}

export default function AppBar({ openSearch, numPendingInvoices }: AppBarProps) {
  const {
    showPendingInvoices,
    close,
    isOpen: isInvoiceDrawerOpen,
    isShowingPendingInvoices,
  } = useContext(InvoiceDetailDrawerFlowContext);
  const {
    userGroup: { file: logo },
    userDetails: { role: userRole },
  } = useContext(UserContext);
  const isFetching = useIsFetching();
  const [searchLabel, setSearchLabel] = useState('Search');
  const { filters } = useGlobalFilters();
  const active = isInvoiceDrawerOpen && isShowingPendingInvoices;

  // Although hacky we must do this in useEffect because so that it gets re-evaluated when the browser
  useEffect(() => {
    setSearchLabel(
      `Search (${
        window.navigator.platform.toLocaleLowerCase().startsWith('mac') ? '⌘' : 'ctrl'
      } + K)`,
    );
  }, []);

  return (
    <>
      <MUIAppBar
        sx={{
          backgroundColor: 'white',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: 'inherit',
        }}
        elevation={1}
      >
        <LinearProgress
          sx={{
            position: 'absolute',
            bottom: -2,
            width: '100%',
            display: isFetching ? 'block' : 'none',
          }}
          color="info"
        />

        <Toolbar>
          {logo ? (
            <Box sx={{ paddingRight: (theme) => theme.spacing(3) }}>
              <img src={logo} alt="Consultant Company" style={{ height: '36px' }} />
            </Box>
          ) : null}
          {canAccess(userRole, UNIVERSAL_SEARCH) && (
            <Box borderRadius={1} sx={{ bgcolor: (theme) => theme.palette.action.selected }}>
              <IconButton type="button" aria-label={searchLabel} onClick={openSearch}>
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1 }}
                onClick={openSearch}
                readOnly
                placeholder={searchLabel}
                inputProps={{ 'aria-label': searchLabel }}
              />
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {canAccess(userRole, PENDING_INVOICES_NOTIFICATION) && (
            <Box sx={{ mr: 1 }}>
              <AppBarButton
                onClick={(e) => {
                  e.preventDefault();
                  if (isInvoiceDrawerOpen && isShowingPendingInvoices) {
                    close();
                  } else {
                    showPendingInvoices();
                  }
                }}
                icon={
                  <Badge
                    data-testid="app-bar-pending-invoices-badge"
                    badgeContent={numPendingInvoices}
                    color="primary"
                  >
                    <InvoiceIcon />
                  </Badge>
                }
                active={active}
                label="Review Pending"
              />
            </Box>
          )}

          {canAccess(userRole, UPLOAD_DOCUMENT_FORM) && <UploadDocumentButton />}
        </Toolbar>
      </MUIAppBar>
      <Offset />
      <Box sx={{ padding: 2, backgroundColor: '#F9FAFB' }}>
        {filters.length > 0 && (
          <>
            <Grid
              container
              pb={1}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
            >
              {filters.map(({ name, component }) => (
                <Grid key={name} item display="flex" alignItems="center">
                  {component}
                </Grid>
              ))}
            </Grid>
            <Divider />
          </>
        )}
      </Box>
    </>
  );
}
