import { Button, DialogActions, type DialogActionsProps, Grid, useTheme } from '@mui/material';

export type ActionButton = {
  label: string;
  enabled: boolean;
};
export type IDialogBackNextActionButtons = {
  back?: ActionButton;
  next?: ActionButton;
  skip?: ActionButton;
};

interface IDialogBackNextActionsProps extends Omit<DialogActionsProps, 'children'> {
  onBack: () => void;
  onNext?: () => void;
  onSkip?: () => void;
  nextDisabled?: boolean;
  actionBtns?: IDialogBackNextActionButtons;
}

const DialogBackNextActions = ({
  onBack,
  onNext,
  onSkip,
  nextDisabled,
  actionBtns = {
    back: { label: 'Back', enabled: true },
    next: { label: 'Next', enabled: true },
    skip: { label: 'Skip', enabled: true },
  },
  sx,
  ...props
}: IDialogBackNextActionsProps) => {
  const theme = useTheme();
  return (
    <DialogActions
      {...props}
      sx={{
        marginX: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: actionBtns.back ? 'space-between' : 'flex-end',
        ...sx,
      }}
    >
      {actionBtns.back ? (
        <Button type="button" disabled={!actionBtns.back.enabled} onClick={onBack} color="primary">
          {actionBtns.back.label}
        </Button>
      ) : null}

      <Grid>
        {actionBtns.skip ? (
          <Button
            type="button"
            onClick={onSkip}
            color="primary"
            variant="outlined"
            disabled={!actionBtns.skip.enabled}
            sx={{ marginRight: theme.spacing(1) }}
          >
            {actionBtns.skip.label}
          </Button>
        ) : null}

        {actionBtns.next ? (
          <Button
            type="submit"
            onClick={onNext}
            color="primary"
            variant="contained"
            disabled={!actionBtns.next.enabled}
          >
            {actionBtns.next.label}
          </Button>
        ) : null}
      </Grid>
    </DialogActions>
  );
};
export default DialogBackNextActions;
