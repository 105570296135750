import useInvoices from '~/data/hooks/useInvoices';

/**
 * A hook which returns a list of Invoices which are in a pending state. It
 * updates any time the `useInvoices()` hook does to provide an up-to-date list
 * of pending Invoices.
 *
 * @see useInvoices
 **/
export default function usePendingInvoices() {
  const {
    invoices: pendingInvoices,
    isLoading,
    error,
  } = useInvoices({ status: ['pending', 'pending-data'] });

  return { pendingInvoices, isLoading, error };
}
