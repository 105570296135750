import { Box } from '@mui/material';

import type { TabDefinition } from '~/components/common/tabs/definition';

interface TabPanelProps {
  hidden: boolean;
  tab: TabDefinition;
  children?: React.ReactNode;
}

export default function TabPanel({ hidden, tab: { panelId, tabId }, children }: TabPanelProps) {
  return (
    <Box
      role="tabpanel"
      id={panelId}
      display={hidden ? 'none' : 'flex'}
      width="100%"
      aria-labelledby={tabId}
      sx={{ flex: '1 0 auto' }}
    >
      {children}
    </Box>
  );
}
