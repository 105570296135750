import z from 'zod';
export const addressSchema = z.object({
    id: z.number(),
    formatted: z.string(),
    streetNumber: z.string(),
    street: z.string().nullable(),
    city: z.string().nullable(),
    postalCode: z.string().nullable(),
    state: z.string().nullable(),
    country: z.string().nullable(),
});
