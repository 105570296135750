import Delete from '@mui/icons-material/Delete';
import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import type { Control } from 'react-hook-form';
import { Controller, useFieldArray } from 'react-hook-form';

import type { FieldDef } from '../form/utils';

interface ILineItemsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  fieldDef: FieldDef;
  errorMessage?: string;
}

const LineItems: React.FC<ILineItemsProps> = ({ control, fieldDef, errorMessage }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldDef.name,
  });

  const handleAddRow = () => {
    append({ description: '', amount: 0 });
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell>
                <Controller
                  name={`${fieldDef.name}.${index}.description`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      error={typeof error !== 'undefined'}
                      helperText={error?.message}
                      fullWidth
                      size="small"
                      required
                      inputProps={{
                        'aria-label': `Line Item ${index + 1} Description`,
                        role: 'textbox',
                      }}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Controller
                  name={`${fieldDef.name}.${index}.amount`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      value={field.value ?? ''}
                      onBlur={field.onBlur}
                      onChange={(e) =>
                        field.onChange(
                          e.target.value != '' ? parseFloat(e.target.value) : e.target.value
                        )
                      }
                      type="number"
                      inputProps={{
                        'aria-label': `Line Item ${index + 1} Amount`,
                        role: 'textbox',
                        min: 0,
                        step: 0.01,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      required
                      error={typeof error !== 'undefined'}
                      helperText={error?.message}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <IconButton onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <Typography variant="body2" color="error">
          {errorMessage ? errorMessage : null}
        </Typography>
      </div>
      <div>
        <Button onClick={handleAddRow}>+ Add Row</Button>
      </div>
    </>
  );
};

export default LineItems;
