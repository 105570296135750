import { InvoiceAttachmentDetail, TicketAttachmentDetail } from '@liftai/asset-management-types';

import { getApiClient } from '~/utils/api';

export interface IUploadEntityFileRequest {
  attachment: string;
  isPrimary: boolean;
  isNextPage: boolean;
  isVoid: boolean;
  pageNumber?: number;
  invoiceId?: string;
  ticketId?: string;
  id?: string;
}

export const associateFileToEntity = async (
  attachmentId: string,
  entityId: string,
  entityType: 'invoice' | 'ticket' = 'invoice',
  {
    isPrimary = false,
    isNextPage = false,
    pageNumber = 1,
    isVoid = false,
  }: {
    isPrimary?: boolean;
    isNextPage?: boolean;
    pageNumber?: number;
    isVoid?: boolean;
  },
) => {
  const data = {
    attachment: attachmentId,
    isPrimary,
    isNextPage,
    isVoid,
    pageNumber,
    invoiceId: entityType === 'invoice' ? entityId : undefined,
    ticketId: entityType === 'ticket' ? entityId : undefined,
  };

  const apiClient = getApiClient();
  await (entityType === 'invoice'
    ? apiClient.invoices.createAttachment(entityId, data)
    : apiClient.tickets.createAttachment(entityId, data));
};

export const updateAssociationFileToEntity = async (
  attachmentId: string,
  entityId: string,
  entityType: 'invoice' | 'ticket' = 'invoice',
  {
    isPrimary = false,
    isNextPage = false,
    isVoid = false,
    id,
  }: {
    isPrimary?: boolean;
    isNextPage?: boolean;
    isVoid?: boolean;
    id: string;
  },
) => {
  const data = {
    attachment: attachmentId,
    id,
    isPrimary,
    isNextPage,
    isVoid,
    invoiceId: entityType === 'invoice' ? entityId : undefined,
    ticketId: entityType === 'ticket' ? entityId : undefined,
  };

  const apiClient = getApiClient();
  const invoiceResult = await (entityType === 'invoice'
    ? apiClient.invoices.updateAttachment(entityId, data)
    : apiClient.tickets.updateAttachment(entityId, data));

  return invoiceResult;
};

export function getFilesAssociatedToEntity(
  entityId: string,
  entityType: 'invoice',
): Promise<InvoiceAttachmentDetail[]>;
export function getFilesAssociatedToEntity(
  entityId: string,
  entityType: 'ticket',
): Promise<TicketAttachmentDetail[]>;
export async function getFilesAssociatedToEntity(
  entityId: string,
  entityType: 'invoice' | 'ticket',
) {
  const apiClient = getApiClient();
  if (entityType === 'invoice') {
    return apiClient.invoices
      .getAttachments(entityId)
      .then((attachments) => attachments.filter((attachment) => attachment.invoice === entityId));
  } else {
    return apiClient.tickets.getAttachments(entityId);
  }
}
