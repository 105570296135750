import type {
  ApproveInvoiceForm,
  Invoice,
  PartialApproveInvoiceForm,
  RejectInvoiceForm,
  ResetInvoiceForm,
} from '@liftai/asset-management-types';
import {
  InvoiceActionType,
  invoiceKindToLabelMap,
  isInvoiceFinal,
} from '@liftai/asset-management-types';
import { Box } from '@mui/material';
import { useMemo, useRef } from 'react';

import { InvoiceActions, InvoiceDetails, InvoiceTabContainer } from '~/components/invoices';
import { useStampedInvoice } from '~/components/invoices/stampedInvoice';
import useInvoice from '~/data/hooks/useInvoice';
import useInvoiceTickets from '~/data/hooks/useInvoiceTickets';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';

interface InvoiceDetailContainerProps {
  onClose: () => void;
  invoice: Invoice | undefined;
}

export default function InvoiceDetailContainer({ onClose, invoice }: InvoiceDetailContainerProps) {
  const { showEntityActionSnackbar } = useLAISnackbar();

  const invoiceId = invoice?.id ?? null;
  const { reviewInvoice } = useInvoice(invoiceId);
  const { tickets } = useInvoiceTickets(invoiceId);

  const invoiceRef = useRef<HTMLDivElement>(null);
  const { downloadStampedInvoice, StampedInvoiceContainer } = useStampedInvoice(invoiceRef);

  const invoiceActions = useMemo(
    () => ({
      onApprove: async (formData: ApproveInvoiceForm) => {
        await reviewInvoice(InvoiceActionType.APPROVE, formData);
      },
      onReset: async (formData: ResetInvoiceForm) => {
        await reviewInvoice(InvoiceActionType.RESET, formData);
        if (!invoice) return;
        showEntityActionSnackbar(
          {
            name: invoiceKindToLabelMap.get(invoice.kind)!,
            action: ActionEnum.Update,
            id: invoice.number,
          },
          { variant: 'info' },
        );
      },
      onReject: async (formData: RejectInvoiceForm) => {
        await reviewInvoice(InvoiceActionType.REJECT, formData);
      },
      onPartial: async (formData: PartialApproveInvoiceForm) => {
        await reviewInvoice(InvoiceActionType.PARTIAL, formData);
      },
      onRequestData: async () => {
        await reviewInvoice(InvoiceActionType.REQUEST_DATA);
        if (!invoice) return;
        showEntityActionSnackbar(
          {
            name: invoiceKindToLabelMap.get(invoice.kind)!,
            action: ActionEnum.Update,
            id: invoice.number,
          },
          { variant: 'info' },
        );
      },
      onDownloadPDF: async (includeSupportingData: boolean = false) => {
        if (invoice) {
          await downloadStampedInvoice({ invoiceId: invoice.id, includeSupportingData });
        } else {
          console.warn("Can't download PDF for an invoice that does not exist");
        }
      },
    }),
    [reviewInvoice, invoice, showEntityActionSnackbar, downloadStampedInvoice],
  );

  return (
    <>
      <Box display={'flex'} flexDirection="column" height="100%">
        <Box>
          <InvoiceDetails invoice={invoice} onClose={onClose} />
        </Box>
        <InvoiceTabContainer invoice={invoice} tickets={tickets} />
        <Box>
          <InvoiceActions {...invoiceActions} invoice={invoice} />
        </Box>
      </Box>
      {invoice && isInvoiceFinal(invoice) ? <StampedInvoiceContainer invoice={invoice} /> : null}
    </>
  );
}
