import z from 'zod';
import { addressSchema } from './address';
import { consultantGroupSchema } from './consultantGroup';
import { metadataSchema } from './metadata';
import { portfolioSchema } from './portfolio';
import { propertyManagerSchema } from './propertyManager';
import { providerSchema } from './provider';
import { userSchema } from './user';
export const propertySchema = z.object({
    id: z.string(),
    name: z.string(),
    address: addressSchema.nullable().optional(),
    region: z.string().nullable(),
    banks: z
        .array(z.object({
        id: z.string(),
        name: z.string(),
        property: z.string(),
    }))
        .nullable(),
    propertyManager: propertyManagerSchema.nullable(),
    consultantGroup: consultantGroupSchema.nullable(),
    assignedConsultant: userSchema.nullable().optional(),
    portfolio: portfolioSchema.nullable().optional(),
    serviceProviders: z.array(providerSchema.optional().nullable()).nullable().optional(),
    metadata: metadataSchema.array().optional(),
    buildingNumber: z.string().optional(),
});
export const propertyDisplaySchema = z.object({
    id: z.string(),
    name: z.string(),
    portfolio: portfolioSchema.nullable().optional(),
    address: z.string().optional(),
});
