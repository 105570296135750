import { redirect } from 'react-router';

import { getDefaultMonthRange } from '~/components/date/MonthRangePicker/MonthRangePicker';

export const ensureDateRangeInParamsOrRedirect = (url: URL): [start: string, end: string] => {
  const startDate = url.searchParams.get('start_date');
  const endDate = url.searchParams.get('end_date');

  if (!startDate || !endDate) {
    const [defaultStartDate, defaultEndDate] = getDefaultMonthRange();

    const nextUrl = new URL(url);
    nextUrl.searchParams.set('start_date', defaultStartDate);
    nextUrl.searchParams.set('end_date', defaultEndDate);
    console.log('redirecting to', nextUrl.toString());
    throw redirect(nextUrl.toString());
  }

  return [startDate, endDate];
};

/**
 * QueryParamsBuilder is a utility class to build query parameters for a URL
 * @param params URLSearchParams object to build query parameters
 * @returns QueryParamsBuilder object
 * @example
 * const params = new URLSearchParams();
 * const queryParams = new QueryParamsBuilder(params)
 *  .set('key', 'value')
 * .set('key2', ['value1', 'value2'])
 * .toString();
 * console.log(queryParams); // key=value&key2=value1&key2=value2
 */
export class QueryParamsBuilder {
  /**
   * QueryParamsBuilder is a utility class to build query parameters for a URL
   * @param params URLSearchParams object to build query parameters
   * @returns QueryParamsBuilder object
   * @example
   * const params = new URLSearchParams();
   * const queryParams = new QueryParamsBuilder(params)
   *  .set('key', 'value')
   * .set('key2', ['value1', 'value2'])
   * .toString();
   * console.log(queryParams); // key=value&key2=value1&key2=value2
   */
  constructor(private readonly params: URLSearchParams = new URLSearchParams()) {
    this.params = params;
  }

  /**
   * getRangeParameters is a static utility method to get the URLSearchParams object
   * with start_date and end_date query parameters
   * @param startDate start date to set in the URLSearchParams object
   * @param endDate end date to set in the URLSearchParams object
   * @returns URLSearchParams object
   * @example
   * const params = QueryParamsBuilder.getRangeParameters('2021-01-01', '2021-01-31');
   * console.log(params); // URLSearchParams object
   * console.log(params.toString()); // start_date=2021-01-01&end_date=2021-01-31
   */
  public static getRangeParameters(
    startDate?: string | null,
    endDate?: string | null,
  ): URLSearchParams {
    return new QueryParamsBuilder()
      .set('start_date', startDate)
      .set('end_date', endDate)
      .getParams();
  }

  /**
   * getDateRangeInParams is a static utility method to get the start_date and end_date
   * query parameters from the URL object
   * @param url URL object to get the query parameters
   * @returns [start_date, end_date] or undefined if the query parameters are not present
   * @example
   * const url = new URL('http://localhost:3000?start_date=2021-01-01&end_date=2021-01-31');
   * const dateRange = QueryParamsBuilder.getDateRangeInParams(url);
   * console.log(dateRange); // ['2021-01-01', '2021-01-31']
   */
  public static getDateRangeInParams(url: URL): [start: string | null, end: string | null] {
    const startDate = url.searchParams.get('start_date');
    const endDate = url.searchParams.get('end_date');

    return [startDate, endDate];
  }

  /**
   * redirectIfNoDefaults is a static utility method to redirect to the default date range
   * if the start_date and end_date query parameters are not present in the URL
   * @param url URL object to check the query parameters
   * @example
   * const url = new URL('http://localhost:3000');
   * QueryParamsBuilder.redirectIfNoDefaults(url); // redirects to the default date range being the last 12 months
   * @example
   * const url = new URL('http://localhost:3000?start_date=2021-01-01&end_date=2021-01-31');
   * QueryParamsBuilder.redirectIfNoDefaults(url); // does not redirect
   */
  public static redirectIfNoDefaults(url: URL): void {
    const startDate = url.searchParams.get('start_date');
    const endDate = url.searchParams.get('end_date');

    if (!startDate || !endDate) {
      const [defaultStartDate, defaultEndDate] = getDefaultMonthRange();

      const nextUrl = new URL(url);
      nextUrl.searchParams.set('start_date', defaultStartDate);
      nextUrl.searchParams.set('end_date', defaultEndDate);
      console.log('redirecting to', nextUrl.toString());

      throw redirect(nextUrl.toString());
    }
  }

  /**
   * set method is used to set a key-value pair in the URLSearchParams object
   * using fluid interface
   * @param key key to set in the URLSearchParams object
   * @param value value to set in the URLSearchParams object
   * @returns QueryParamsBuilder object
   * @example
   * const params = new URLSearchParams();
   * const queryParams = new QueryParamsBuilder(params)
   * .set('key', 'value') // set a value
   * .set('key2', ['value1', 'value2']) // set an array of values
   */
  public set(key: string, value?: string | string[] | number | null): QueryParamsBuilder {
    if (value === undefined || value === null) {
      return this;
    }

    if (Array.isArray(value)) {
      for (const v of value) {
        this.params.append(key, v);
      }
    } else {
      this.params.set(key, value.toString());
    }

    return this;
  }

  /**
   * get method is used to get the value of a key from the URLSearchParams object
   * @param key key to get the value from the URLSearchParams object
   * @returns value of the key or null if the key is not present
   * @example
   * const params = new URLSearchParams();
   * const queryParams = new QueryParamsBuilder(params)
   * .set('key', value)
   * .get('key'); // value
   * console.log(queryParams); // key=value
   */
  public get(key: string): string | null {
    return this.params.get(key);
  }

  /**
   * getParams method is used to get the URLSearchParams object
   * @returns URLSearchParams object
   * @example
   * const params = new URLSearchParams();
   * const queryParams = new QueryParamsBuilder(params)
   * .set('key',value)
   * .getParams();
   * console.log(queryParams); // URLSearchParams object
   */
  public getParams(): URLSearchParams {
    return this.params;
  }

  /**
   * toString method is used to get the query parameters as a string
   * @returns query parameters as a string
   * @example
   * const params = new URLSearchParams();
   * const queryParams = new QueryParamsBuilder(params)
   * .set('key',value)
   * .toString();
   * console.log(queryParams); // key=value
   */
  public toString(): string {
    return this.params.toString();
  }
}
