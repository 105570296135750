import type {
  CarInProperty,
  DisabledFields,
  EntryTypeKey,
  PropertyForm,
} from '@liftai/asset-management-types';
import {
  entryTypeOptions,
  isOfCallBackType,
  problemTypeOptions,
} from '@liftai/asset-management-types';
import type { InvoiceFormOptions } from '@liftai/asset-management-types/src/api/invoices';

import type { FieldDef } from '~/components/form/utils';

interface TicketFieldDefOptions {
  properties?: PropertyForm[];
  ticketType: EntryTypeKey;
  cars?: CarInProperty[];
  invoices?: InvoiceFormOptions;
  disabledFields: DisabledFields;
}

export const buildFieldDef = ({
  properties,
  cars,
  ticketType,
  invoices,
  disabledFields,
}: TicketFieldDefOptions) => {
  const generalFields: FieldDef[] = [
    /** TODO: Ticket hidden properties:
     *  Date, Service Provider and Property Manager
     *  In the API integration issue, these values will be
     *  inferred as follows:
     * - Date -> Date.now()
     * - Service Provider -> From Car
     * - Property Manager -> From Property */
    {
      name: 'propertyId',
      label: 'Property',
      type: 'autocomplete',
      options:
        properties?.map((property) => ({
          id: property.id,
          label: property.name,
        })) ?? [],
      loading: properties === undefined,
      disabled: disabledFields['propertyId'] ?? false,
    },
    {
      name: 'invoiceId',
      label: 'Invoice #',
      type: 'autocomplete',
      options: invoices?.map((invoice) => ({ id: invoice.id, label: invoice.number })) ?? [],
      loading: invoices === undefined,
      required: false,
      disabled: disabledFields['invoiceId'] ?? false,
    },
  ];

  const maintenanceFields: FieldDef[] = [
    {
      name: 'startedTime',
      label: 'Arrival Time',
      type: 'datetime-local',
      disabled: disabledFields['startedTime'] ?? false,
    },
    {
      name: 'finishedTime',
      label: 'Departure Time',
      type: 'datetime-local',
      disabled: disabledFields['finishedTime'] ?? false,
    },
  ];

  const callBackFields: FieldDef[] = [
    {
      name: 'callTime',
      label: 'Call Time',
      type: 'datetime-local',
      required: false,
      disabled: disabledFields['callTime'] ?? false,
    },
    { name: 'callTime-spacer', type: 'spacer' },
    {
      name: 'startedTime',
      label: 'Response Time',
      type: 'datetime-local',
      disabled: disabledFields['startedTime'] ?? false,
    },
    {
      name: 'finishedTime',
      label: 'Resolution Time',
      type: 'datetime-local',
      disabled: disabledFields['startedTime'] ?? false,
    },
  ];

  const ticketFields: FieldDef[] = [
    {
      name: 'number',
      label: 'Ticket Number',
      type: 'text',
      disabled: disabledFields['number'] ?? false,
    },

    {
      name: 'entryType',
      label: 'Entry Type',
      type: 'select',
      options: entryTypeOptions.map(([value, label]) => ({ id: value, label })),
      disabled: disabledFields['entryType'] ?? false,
    },
    // only show problem description if ticketType is callBackType
    ...(isOfCallBackType(ticketType)
      ? [
          {
            name: 'problemType',
            label: 'Problem Type',
            type: 'select',
            gridProps: { xs: 12 },
            required: false,
            options: problemTypeOptions.map((value) => ({ id: value, label: value })),
            disabled: disabledFields['problemType'] ?? false,
          } as FieldDef,
          {
            name: 'problemDescription',
            label: 'Problem Description',
            type: 'text',
            gridProps: { xs: 12 },
            disabled: disabledFields['problemDescription'] ?? false,
          } as FieldDef,
        ]
      : []),
    // if ticketType is callBackType, the show corrective action otherwise show work performed
    {
      name: 'workPerformed',
      label: isOfCallBackType(ticketType) ? 'Corrective Action' : 'Work Performed',
      type: 'text',
      gridProps: { xs: 12 },
      disabled: disabledFields['workPerformed'] ?? false,
    } as FieldDef,
    {
      name: 'carIds',
      label: 'Cars Serviced',
      type: 'autocomplete-multiple',
      gridProps: { xs: 12 },
      options: cars
        ? cars.map((car) => ({
            id: car.id,
            label: car.name + (car.ahjId ? ` - ${car.ahjId}` : ''),
          }))
        : [],
      loading: cars === undefined,
      disabled: disabledFields['carIds'] ?? false,
    },
    ...(isOfCallBackType(ticketType) ? callBackFields : maintenanceFields),
    {
      name: 'timeOnSiteRegularHours',
      label: 'Time on Site (Regular Hours)',
      type: 'time',
      required: false,
      disabled: disabledFields['timeOnSiteRegularHours'] ?? false,
    },
    {
      name: 'travelTimeRegularHours',
      label: 'Travel Time (Regular Hours)',
      type: 'time',
      required: false,
      disabled: disabledFields['travelTimeRegularHours'] ?? false,
    },
    {
      name: 'timeOnSiteOvertime',
      label: 'Time on Site (Overtime)',
      type: 'time',
      required: false,
      disabled: disabledFields['timeOnSiteOvertime'] ?? false,
    },
    {
      name: 'travelTimeOvertime',
      label: 'Travel Time (Overtime)',
      type: 'time',
      required: false,
      disabled: disabledFields['travelTimeOvertime'] ?? false,
    },
  ];

  const checkboxField: FieldDef = {
    name: 'countTowardsKpis',
    label: isOfCallBackType(ticketType)
      ? 'Count Towards Callback Rate'
      : 'Count Towards Maintenance Completion',
    type: 'checkbox',
    gridProps: { xs: 12 },
    disabled: disabledFields['countTowardsKpis'] ?? false,
  };

  const notesField: FieldDef = {
    name: 'notes',
    label: 'Notes',
    type: 'text',
    required: false,
    gridProps: { xs: 12 },
    disabled: disabledFields['notes'] ?? false,
  };

  return {
    generalFields,
    ticketFields,
    checkboxField,
    notesField,
  };
};
